<template>
    <el-main class="pad-t-15 pad-lr-15">
        <el-row :gutter="20" class="mar-b-15">
            <el-col :lg="8" :md="8" :sm="24">
                <router-link to="/activemandates">
                    <div class="sp-box">
                        <span>
                            {{ $t('headers.activeMandates') }}
                        </span>
                        <div class="sp-box-badge">{{ $root.mandateCounter }}</div>
                    </div>
                </router-link>
            </el-col>
            <el-col :lg="8" :md="8" :sm="24">
                <router-link to="/offer">
                    <div class="sp-box">
                        <span>
                            {{ $t('headers.ENEAOffer') }}
                        </span>
                        <div class="sp-box-badge">{{ $root.offerCounter }}</div>
                    </div>
                </router-link>
            </el-col>
            <el-col :lg="8" :md="8" :sm="24">
                <router-link to="/message">
                    <div class="sp-box">
                        <span>
                            {{ $t('headers.Messages') }}
                        </span>
                        <div class="sp-box-badge">{{ $root.messageCounter }}</div>
                    </div>
                </router-link>
            </el-col>
        </el-row>

        <el-row :gutter="20" class="mar-t-15">
            <el-col :lg="12" :md="24" :sm="24">
                <el-row :gutter="20" class="mar-tb-15">
                    <el-col :lg="24" :md="24" :sm="24" v-loading="contractsLoading">
                        <DashboardContractListCmp ref="dashboardContract" v-if="!contractsLoading" :total="this.contractsTotal"
                            :contracts="this.contracts" :parent="this">
                        </DashboardContractListCmp>
                        <div v-else style="height:300px;background-color:transparent;">
                            <el-empty description="..." />
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mar-tb-15">
                    <el-col :lg="24" :md="24" :sm="24">
                        <DashboardTransactionsListCmp v-if="!transactionsLoading" :total="this.transactionsTotal"
                            :transactions="this.transactions"></DashboardTransactionsListCmp>
                        <div v-else style="height:300px;background-color:transparent;">
                            <el-empty description="..." />
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :lg="12" :md="24" :sm="24">
                <el-row :gutter="20" class="mar-tb-15">
                    <el-col :lg="24" :md="24" :sm="24" style="padding-right:30px;">
                        <DashboardTGECmp ref="dashboardTGE" 
                            :mediumTypeRadioTGE="this.tgequotesMediumTypeRadio" :parent="this"></DashboardTGECmp>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </el-main>
</template>

<script>

import { Helpers } from '@/scripts/helpers'
import { Renderers } from '@/scripts/renderers'
import DashboardContractListCmp from '@/components/Dashboard/DashboardContractListCmp.vue';
import DashboardTransactionsListCmp from '@/components/Dashboard/DashboardTransactionListCmp.vue';
import DashboardTGECmp from '@/components/Dashboard/DashboardTGECmp.vue';
import { Notifications } from '@/scripts/notifications'
import { Logger } from '@/scripts/logger'
import * as contractsApi from '@/api/ContractApi'
import * as offerApi from '@/api/OfferApi'
import * as messageApi from '@/api/MessageApi'
import * as transactionsApi from '@/api/VTransactionApi'
import * as declarationKindApi from '@/api/DeclarationKindApi'

export default {
    setup() {
        return { Helpers, Renderers };
    },
    data() {
        return {
            //contract carousel
            contractsLoading: true,
            contractsCounterLoading: true,
            contractsTotal: null,
            contracts: [],
            selectedContractId: null,
            selectedContractMedium: "EE,PR,GAS",

            //last transaction list
            transactionsLoading: true,
            transactionsTotal: 0,
            transactions: [],

            //tge chart with quotes list
            tgeQuotesLoading: true,
            tgequotesMediumTypeRadio: null,
            firstContractMedium: null,
        };
    },
    components: {
        DashboardContractListCmp,
        DashboardTransactionsListCmp,
        DashboardTGECmp
    },
    mounted() {
        let self = this;

        self.fetchFirstContractsCarousel();
        self.fetchContractsCarousel();
        self.fetchTGEQuotes();

        //self.fetchTransactionList(); //ładowane na onContractChange() w fetchContractsCarousel()
    },
    methods: {
        fetchFirstContractsCarousel: function () {
            this.contractsLoading = true;
            this.contractsCounterLoading = true;

            contractsApi.getPage({
                searchModel: JSON.stringify({}),
                pageIndex: 1,
                pageSize: 2
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        this.contractsLoading = false;
                        return;
                    }

                    this.contracts = response.data.Model.data;

                    if (this.contracts?.length > 0) {
                      this.onContractChange(0);
                    };

                    this.contractsLoading = false;
                },
                (error) => {
                    Logger.logException(error);

                    this.contractsLoading = false;
                }
            );
        },
        fetchContractsCarousel: function () {
            this.contractsLoading = true;
            this.contractsCounterLoading = true;

            contractsApi.getPage({
                searchModel: JSON.stringify({}),
                pageIndex: 1,
                pageSize: 999
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        this.contractsLoading = false;
                        return;
                    }

                    this.contracts = response.data.Model.data;
                    this.contractsTotal = response.data.Model.data.length;

                    this.contractsLoading = false;
                    this.contractsCounterLoading = false;
                },
                (error) => {
                    Logger.logException(error);

                    this.contractsLoading = false;
                    this.contractsCounterLoading = false;
                }
            );
        },
        fetchTransactionList: function () {
            this.transactionsLoading = true;
            this.transactions = [];

            var transactionSearchModel = JSON.stringify({ ContractId: this.selectedContractId > 0 ? this.selectedContractId : -1 });

            transactionsApi.getPage({
                searchModel: transactionSearchModel,
                pageIndex: 1,
                pageSize: 5
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        this.transactionsLoading = false;
                        return;
                    }

                    this.transactions = response.data.Model.data;
                    this.transactionsTotal = response.data.Model.data.length;

                    this.transactionsLoading = false;
                },
                (error) => {
                    Logger.logException(error);

                    this.transactionsLoading = false;
                }
            );
        },
        fetchTGEQuotes: function () {
            this.tgequotesMediumTypeRadio = 'EE';
        },
        onContractChange: function (newIndex) {
            this.selectedContractId = this.contracts[newIndex].Id;
            this.selectedContractMedium = this.contracts[newIndex].Medium;

            if (this.$refs.dashboardTGE) {
                this.$refs.dashboardTGE.refresh = true;
                this.$refs.dashboardTGE.showQuotations = true;
                this.$refs.dashboardTGE.showIndexChart = false;
            };

            this.firstContractMedium = null;
            var contractMediums = this.selectedContractMedium?.split(',');
            if (contractMediums?.length > 0)
                this.firstContractMedium = contractMediums[0];

            this.fetchDeclarationKids();

            this.fetchTransactionList();
        },
        fetchDeclarationKids: function () {
            if (this.$refs.dashboardContract) {
                this.$refs.dashboardContract.declarationKinds = [];
            }

            declarationKindApi.getPage({
                searchModel: JSON.stringify({ ContractId: this.selectedContractId > 0 ? this.selectedContractId : -1 }),
                pageIndex: 1,
                pageSize: 10000
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        return;
                    }
                    if (this.$refs.dashboardContract) {
                        this.$refs.dashboardContract.declarationKinds = response?.data?.Model?.data;
                    }
                },
                (error) => {
                    Logger.logException(error);
                }
            );
        },
    },
    computed: {

    },
}

</script>
