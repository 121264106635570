import moment from 'moment'
import { Renderers } from '@/scripts/renderers'

export const Common = {
    data() {
        return {
            smallScreen: window.innerWidth <= 800,
            screenSize: window.innerWidth,
        };
    },
    created() {
        window.addEventListener("resize", this.checkScreenSize);
    },
    methods: {
        //navigator
        handleTabClick(tab, event) {
            if (!tab?.props) {
                return;
            };

            window.location.hash = tab.props.name;

            try {
                switch (tab.props.name) {
                    default:
                        this.loadFormBase();
                        break;
                }
            }
            catch (ex) {
                //
            }
        },
        loadTabFromHash() {
            if (window.location.hash.length > 1) {
                var tab = window.location.hash.replace('#', '');
                this.activeTabName = tab;
                this.handleTabClick({ props: { name: tab } });
                return true;
            }
            else {
                return false;
            };
        },
        onHashChange: function () {
            this.loadTabFromHash();
        },

        registerComponent: function (cmpName, cmp) {
            this[cmpName] = cmp;
        },
        checkScreenSize: function () {
            this.smallScreen = window.innerWidth <= 800;
        },

        clearPasteValue: function (e) {
            if (e.clipboardData) {
                e.stopPropagation();
                e.preventDefault();

                var pastedData = e.clipboardData.getData('Text');

                var newData = pastedData.replace(/\u00A0/g, ""); //non-braking space

                e.target.value = newData;
                e.target.dispatchEvent(new Event('input'));
            }
        },

        /*
        Check if given date is in the future
        */
        dateIsFuture: function (date) {
            if (!date)
                return false;

            var parsedDate = moment(Renderers.formatAsDateFromProc(date));

            return parsedDate > new Date();
        },
    }
}