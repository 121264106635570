<template>
    <el-row :gutter="20">
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
            <el-alert  style="margin-bottom:15px; max-width:300px;background-color:transparent;" :closable="false">
                <div class="frmValidationRules">
                    <p class="frmValidation" :class="{ 'frmValidation--passed': has_minChars && has_maxChars }">
                        <el-icon class="el-icon frmIcon"><span
                                :class="has_minChars && has_maxChars ? 'mdi mdi-check' : 'mdi mdi-close'"></span></el-icon>
                        <span class="frmValidationRule">
                            {{ $t('password.BetweenChars').replace('#min', this.minChars).replace('#max', this.maxChars) }}
                        </span>
                    </p>
                    <p class="frmValidation" :class="{ 'frmValidation--passed': has_uppercase }">
                        <el-icon class="el-icon frmIcon"><span
                                :class="has_uppercase ? 'mdi mdi-check' : 'mdi mdi-close'"></span></el-icon>
                        <span class="frmValidationRule">
                            {{ $t('password.Uppercase') }}
                        </span>
                    </p>
                    <p class="frmValidation" :class="{ 'frmValidation--passed': has_lowercase }">
                        <el-icon class="el-icon frmIcon"><span
                                :class="has_lowercase ? 'mdi mdi-check' : 'mdi mdi-close'"></span></el-icon>
                        <span class="frmValidationRule">
                            {{ $t('password.Lowercase') }}
                        </span>
                    </p>
                    <p class="frmValidation" :class="{ 'frmValidation--passed': has_number }">
                        <el-icon class="el-icon frmIcon"><span
                                :class="has_number ? 'mdi mdi-check' : 'mdi mdi-close'"></span></el-icon>
                        <span class="frmValidationRule">
                            {{ $t('password.Number') }}
                        </span>
                    </p>
                    <p class="frmValidation" :class="{ 'frmValidation--passed': has_special }">
                        <el-icon class="el-icon frmIcon"><span
                                :class="has_special ? 'mdi mdi-check' : 'mdi mdi-close'"></span></el-icon>
                        <span class="frmValidationRule">
                            {{ $t('password.Special') }}
                        </span>
                    </p>
                </div>
            </el-alert>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "PasswordStrenthIndicator",

    props: {
        message: String,
        minChars: Number,
        maxChars: Number
    },

    data: function () {
        return {
            message: '',
            has_minChars: false,
            has_maxChars: false,
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false
        }
    },
    computed: {
        validationStatus: function () {
            var validationResults = [
                this.has_minChars && this.has_maxChars,
                this.has_number,
                this.has_lowercase,
                this.has_uppercase,
                this.has_special
            ];

            if (validationResults.includes(true) && !validationResults.includes(false)) {
                return 'success';
            }
            else if (validationResults.includes(false) && validationResults.includes(true)) {
                return 'warning';
            }
            else if (validationResults.includes(false) && !validationResults.includes(true)) {
                return 'error';
            }
            else {
                return "error";
            };
        },
    },
    watch: {
        $props: {
            immediate: true,
            deep: true,
            handler(val, oldVal) {
                this.password_check(val.message, val.minChars, val.maxChars);
            }
        }
    },
    methods: {
        password_check: function (password, minChars, maxChars) {
            this.has_minChars = password && password.length >= minChars;
            this.has_maxChars = password && password.length <= maxChars;
            this.has_number = password && /\d/.test(password);
            this.has_lowercase = password && /[a-z]/.test(password);
            this.has_uppercase = password && /[A-Z]/.test(password);
            this.has_special = password && /(?=.*[\W_])/.test(password);

            this.$emit('isCorrect', this.has_minChars && this.has_maxChars && this.has_number
                && this.has_lowercase && this.has_uppercase && this.has_special);
        }
    }
}
</script>