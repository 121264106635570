import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from '../views/Dashboard/Dashboard.vue'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: Dashboard
    },
    {
        path: '/pageunavailable/:id',
        name: 'pageunavailable',
        component: () => import('../views/PageUnavailable.vue')
    },
    {
        path: '/alert',
        name: 'alert',
        component: () => import('../views/Alert/AlertList.vue')
    },
    {
        path: '/alertform/:id',
        name: 'alertform',
        component: () => import('../views/Alert/AlertForm.vue')
    }
    ,
    {
        path: '/alerts',
        name: 'alerts',
        component: () => import('../views/AlertDef/AlertDefList.vue')
    },
    {
        path: '/alertdefform/:id',
        name: 'alertdefform',
        component: () => import('../views/AlertDef/AlertDefForm.vue')
    }
    ,
    {
        path: '/applicantcontract',
        name: 'applicantcontract',
        component: () => import('../views/ApplicantContract/ApplicantContractList.vue')
    },
    {
        path: '/applicantcontractform/:id',
        name: 'applicantcontractform',
        component: () => import('../views/ApplicantContract/ApplicantContractForm.vue')
    }
    ,
    {
        path: '/balance',
        name: 'balance',
        component: () => import('../views/Balance/BalanceList.vue')
    },
    {
        path: '/balanceform/:id',
        name: 'balanceform',
        component: () => import('../views/Balance/BalanceForm.vue')
    }
    ,
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('../views/Calendar/CalendarList.vue')
    },
    {
        path: '/calendarform/:id',
        name: 'calendarform',
        component: () => import('../views/Calendar/CalendarForm.vue')
    }
    ,
    {
        path: '/calendarday',
        name: 'calendarday',
        component: () => import('../views/CalendarDay/CalendarDayList.vue')
    },
    {
        path: '/calendardayform/:id',
        name: 'calendardayform',
        component: () => import('../views/CalendarDay/CalendarDayForm.vue')
    }
    ,
    {
        path: '/complementary',
        name: 'complementary',
        component: () => import('../views/Complementary/ComplementaryList.vue')
    },
    {
        path: '/complementaryform/:id',
        name: 'complementaryform',
        component: () => import('../views/Complementary/ComplementaryForm.vue')
    }
    ,
    {
        path: '/contract/:mediumtype',
        name: 'contract',
        component: () => import('../views/Contract/ContractList.vue')
    },
    {
        path: '/contractform/:id',
        name: 'contractform',
        component: () => import('../views/Contract/ContractForm.vue')
    }
    ,
    {
        path: '/contractaudit',
        name: 'contractaudit',
        component: () => import('../views/ContractAudit/ContractAuditList.vue')
    },
    {
        path: '/contractauditform/:id',
        name: 'contractauditform',
        component: () => import('../views/ContractAudit/ContractAuditForm.vue')
    }
    ,
    {
        path: '/contractrealization',
        name: 'contractrealization',
        component: () => import('../views/ContractRealization/ContractRealizationList.vue')
    },
    {
        path: '/contractrealizationform/:id',
        name: 'contractrealizationform',
        component: () => import('../views/ContractRealization/ContractRealizationForm.vue')
    }
    ,
    {
        path: '/contractrev',
        name: 'contractrev',
        component: () => import('../views/ContractRev/ContractRevList.vue')
    },
    {
        path: '/contractrevform/:id',
        name: 'contractrevform',
        component: () => import('../views/ContractRev/ContractRevForm.vue')
    }
    ,
    {
        path: '/contractsummary',
        name: 'contractsummary',
        component: () => import('../views/ContractSummary/ContractSummaryList.vue')
    },
    {
        path: '/contractsummaryform/:id',
        name: 'contractsummaryform',
        component: () => import('../views/ContractSummary/ContractSummaryForm.vue')
    }
    ,
    {
        path: '/customer',
        name: 'customer',
        component: () => import('../views/Customer/CustomerList.vue')
    },
    {
        path: '/customerform/:id',
        name: 'customerform',
        component: () => import('../views/Customer/CustomerForm.vue')
    }
    ,
    {
        path: '/customercontract',
        name: 'customercontract',
        component: () => import('../views/CustomerContract/CustomerContractList.vue')
    },
    {
        path: '/customercontractform/:id',
        name: 'customercontractform',
        component: () => import('../views/CustomerContract/CustomerContractForm.vue')
    }
    ,
    {
        path: '/customeruser',
        name: 'customeruser',
        component: () => import('../views/CustomerUser/CustomerUserList.vue')
    },
    {
        path: '/customeruserform/:id',
        name: 'customeruserform',
        component: () => import('../views/CustomerUser/CustomerUserForm.vue')
    }
    ,
    {
        path: '/customeruseractivity',
        name: 'customeruseractivity',
        component: () => import('../views/CustomerUserActivity/CustomerUserActivityList.vue')
    },
    {
        path: '/customeruseractivityform/:id',
        name: 'customeruseractivityform',
        component: () => import('../views/CustomerUserActivity/CustomerUserActivityForm.vue')
    }
    ,
    {
        path: '/declarationkind',
        name: 'declarationkind',
        component: () => import('../views/DeclarationKind/DeclarationKindList.vue')
    },
    {
        path: '/declarationkindform/:id',
        name: 'declarationkindform',
        component: () => import('../views/DeclarationKind/DeclarationKindForm.vue')
    }
    ,
    {
        path: '/declarationlimit',
        name: 'declarationlimit',
        component: () => import('../views/DeclarationLimit/DeclarationLimitList.vue')
    },
    {
        path: '/declarationlimitform/:id',
        name: 'declarationlimitform',
        component: () => import('../views/DeclarationLimit/DeclarationLimitForm.vue')
    }
    ,
    {
        path: '/indexcalendar',
        name: 'indexcalendar',
        component: () => import('../views/IndexCalendar/IndexCalendarList.vue')
    },
    {
        path: '/indexcalendarform/:id',
        name: 'indexcalendarform',
        component: () => import('../views/IndexCalendar/IndexCalendarForm.vue')
    }
    ,
    {
        path: '/keyaccountmanager',
        name: 'keyaccountmanager',
        component: () => import('../views/KeyAccountManager/KeyAccountManagerList.vue')
    },
    {
        path: '/keyaccountmanagerform/:id',
        name: 'keyaccountmanagerform',
        component: () => import('../views/KeyAccountManager/KeyAccountManagerForm.vue')
    }
    ,
    {
        path: '/localuser',
        name: 'localuser',
        component: () => import('../views/LocalUser/LocalUserList.vue')
    },
    {
        path: '/localuserform/:id',
        name: 'localuserform',
        component: () => import('../views/LocalUser/LocalUserForm.vue')
    }
    ,
    {
        path: '/localuseractivity',
        name: 'localuseractivity',
        component: () => import('../views/LocalUserActivity/LocalUserActivityList.vue')
    },
    {
        path: '/localuseractivityform/:id',
        name: 'localuseractivityform',
        component: () => import('../views/LocalUserActivity/LocalUserActivityForm.vue')
    }
    ,
    {
        path: '/mandate',
        name: 'mandate',
        component: () => import('../views/Mandate/MandateList.vue')
    },
    {
        path: '/mandateform/:id',
        name: 'mandateform',
        component: () => import('../views/Mandate/MandateForm.vue')
    }
    ,
    {
        path: '/medium',
        name: 'medium',
        component: () => import('../views/Medium/MediumList.vue')
    },
    {
        path: '/mediumform/:id',
        name: 'mediumform',
        component: () => import('../views/Medium/MediumForm.vue')
    }
    ,
    {
        path: '/mediumcalendar',
        name: 'mediumcalendar',
        component: () => import('../views/MediumCalendar/MediumCalendarList.vue')
    },
    {
        path: '/mediumcalendarform/:id',
        name: 'mediumcalendarform',
        component: () => import('../views/MediumCalendar/MediumCalendarForm.vue')
    }
    ,
    {
        path: '/mediumproduct',
        name: 'mediumproduct',
        component: () => import('../views/MediumProduct/MediumProductList.vue')
    },
    {
        path: '/mediumproductform/:id',
        name: 'mediumproductform',
        component: () => import('../views/MediumProduct/MediumProductForm.vue')
    }
    ,
    {
        path: '/mediumproducttype',
        name: 'mediumproducttype',
        component: () => import('../views/MediumProductType/MediumProductTypeList.vue')
    },
    {
        path: '/mediumproducttypeform/:id',
        name: 'mediumproducttypeform',
        component: () => import('../views/MediumProductType/MediumProductTypeForm.vue')
    }
    ,
    {
        path: '/message',
        name: 'message',
        component: () => import('../views/Message/MessageList.vue')
    },
    {
        path: '/messageform/:id',
        name: 'messageform',
        component: () => import('../views/Message/MessageForm.vue')
    }
    ,
    {
        path: '/offer/:contractId?',
        name: 'offer',
        component: () => import('../views/Offer/OfferList.vue')
    },
    {
        path: '/offerform/:id',
        name: 'offerform',
        component: () => import('../views/Offer/OfferForm.vue')
    }
    ,
    {
        path: '/parameter',
        name: 'parameter',
        component: () => import('../views/Parameter/ParameterList.vue')
    },
    {
        path: '/parameterform/:id',
        name: 'parameterform',
        component: () => import('../views/Parameter/ParameterForm.vue')
    }
    ,
    {
        path: '/permission',
        name: 'permission',
        component: () => import('../views/Permission/PermissionList.vue')
    },
    {
        path: '/permissionform/:id',
        name: 'permissionform',
        component: () => import('../views/Permission/PermissionForm.vue')
    }
    ,
    {
        path: '/powerlimit',
        name: 'powerlimit',
        component: () => import('../views/PowerLimit/PowerLimitList.vue')
    },
    {
        path: '/powerlimitform/:id',
        name: 'powerlimitform',
        component: () => import('../views/PowerLimit/PowerLimitForm.vue')
    }
    ,
    {
        path: '/ppa',
        name: 'ppa',
        component: () => import('../views/PPA/PPAList.vue')
    },
    {
        path: '/ppaform/:id',
        name: 'ppaform',
        component: () => import('../views/PPA/PPAForm.vue')
    }
    ,
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/Product/ProductList.vue')
    },
    {
        path: '/productform/:id',
        name: 'productform',
        component: () => import('../views/Product/ProductForm.vue')
    }
    ,
    {
        path: '/product_calendar',
        name: 'product_calendar',
        component: () => import('../views/Product_Calendar/Product_CalendarList.vue')
    },
    {
        path: '/product_calendarform/:id',
        name: 'product_calendarform',
        component: () => import('../views/Product_Calendar/Product_CalendarForm.vue')
    }
    ,
    {
        path: '/quotation',
        name: 'quotation',
        component: () => import('../views/Quotation/QuotationList.vue')
    },
    {
        path: '/quotationform/:id',
        name: 'quotationform',
        component: () => import('../views/Quotation/QuotationForm.vue')
    }
    ,
    {
        path: '/recoverytoken',
        name: 'recoverytoken',
        component: () => import('../views/RecoveryToken/RecoveryTokenList.vue')
    },
    {
        path: '/recoverytokenform/:id',
        name: 'recoverytokenform',
        component: () => import('../views/RecoveryToken/RecoveryTokenForm.vue')
    }
    ,
    {
        path: '/refreshtoken',
        name: 'refreshtoken',
        component: () => import('../views/RefreshToken/RefreshTokenList.vue')
    },
    {
        path: '/refreshtokenform/:id',
        name: 'refreshtokenform',
        component: () => import('../views/RefreshToken/RefreshTokenForm.vue')
    }
    ,
    {
        path: '/role',
        name: 'role',
        component: () => import('../views/Role/RoleList.vue')
    },
    {
        path: '/roleform/:id',
        name: 'roleform',
        component: () => import('../views/Role/RoleForm.vue')
    }
    ,
    {
        path: '/rolepermission',
        name: 'rolepermission',
        component: () => import('../views/RolePermission/RolePermissionList.vue')
    },
    {
        path: '/rolepermissionform/:id',
        name: 'rolepermissionform',
        component: () => import('../views/RolePermission/RolePermissionForm.vue')
    }
    ,
    {
        path: '/runoperation',
        name: 'runoperation',
        component: () => import('../views/RunOperation/RunOperationList.vue')
    },
    {
        path: '/runoperationform/:id',
        name: 'runoperationform',
        component: () => import('../views/RunOperation/RunOperationForm.vue')
    }
    ,
    {
        path: '/runoperationstate',
        name: 'runoperationstate',
        component: () => import('../views/RunOperationState/RunOperationStateList.vue')
    },
    {
        path: '/runoperationstateform/:id',
        name: 'runoperationstateform',
        component: () => import('../views/RunOperationState/RunOperationStateForm.vue')
    }
    ,
    {
        path: '/status',
        name: 'status',
        component: () => import('../views/Status/StatusList.vue')
    },
    {
        path: '/statusform/:id',
        name: 'statusform',
        component: () => import('../views/Status/StatusForm.vue')
    }
    ,
    {
        path: '/status2status',
        name: 'status2status',
        component: () => import('../views/Status2Status/Status2StatusList.vue')
    },
    {
        path: '/status2statusform/:id',
        name: 'status2statusform',
        component: () => import('../views/Status2Status/Status2StatusForm.vue')
    }
    ,
    {
        path: '/statuschange',
        name: 'statuschange',
        component: () => import('../views/StatusChange/StatusChangeList.vue')
    },
    {
        path: '/statuschangeform/:id',
        name: 'statuschangeform',
        component: () => import('../views/StatusChange/StatusChangeForm.vue')
    }
    ,
    {
        path: '/tradingsession',
        name: 'tradingsession',
        component: () => import('../views/TradingSession/TradingSessionList.vue')
    },
    {
        path: '/tradingsessionform/:id',
        name: 'tradingsessionform',
        component: () => import('../views/TradingSession/TradingSessionForm.vue')
    }
    ,
    {
        path: '/tranche',
        name: 'tranche',
        component: () => import('../views/Tranche/TrancheList.vue')
    },
    {
        path: '/trancheform/:id',
        name: 'trancheform',
        component: () => import('../views/Tranche/TrancheForm.vue')
    },
    {
        path: '/trancheapplication/trancheapplication/:contractId/:medium',
        name: 'trancheapplication',
        component: () => import('../views/VTrancheApplication/VTrancheApplicationForm.vue')
    },
    {
        path: '/mandateapplication/mandateapplication/:contractId/:medium',
        name: 'mandateapplication',
        component: () => import('../views/VMandateApplication/VMandateApplicationForm.vue')
    },
    {
        path: '/tradingindex',
        name: 'tradingindex',
        component: () => import('../views/TradingIndex/TradingIndexList.vue')
    },
    {
        path: '/tradingindexform/:id',
        name: 'tradingindexform',
        component: () => import('../views/TradingIndex/TradingIndexForm.vue')
    }
    ,
    {
        path: '/tradingsessionimport',
        name: 'tradingsessionimport',
        component: () => import('../views/TradingSessionImport/TradingSessionImportList.vue')
    },
    {
        path: '/tradingsessionimportform/:id',
        name: 'tradingsessionimportform',
        component: () => import('../views/TradingSessionImport/TradingSessionImportForm.vue')
    }
    ,
    {
        path: '/tradingsessionimportstate',
        name: 'tradingsessionimportstate',
        component: () => import('../views/TradingSessionImportState/TradingSessionImportStateList.vue')
    },
    {
        path: '/tradingsessionimportstateform/:id',
        name: 'tradingsessionimportstateform',
        component: () => import('../views/TradingSessionImportState/TradingSessionImportStateForm.vue')
    }
    ,
    {
        path: '/tradingsessionplan',
        name: 'tradingsessionplan',
        component: () => import('../views/TradingSessionPlan/TradingSessionPlanList.vue')
    },
    {
        path: '/tradingsessionplanform/:id',
        name: 'tradingsessionplanform',
        component: () => import('../views/TradingSessionPlan/TradingSessionPlanForm.vue')
    }
    ,
    {
        path: '/transactionstate',
        name: 'transactionstate',
        component: () => import('../views/TransactionState/TransactionStateList.vue')
    },
    {
        path: '/transactionstateform/:id',
        name: 'transactionstateform',
        component: () => import('../views/TransactionState/TransactionStateForm.vue')
    }
    ,
    {
        path: '/userclaim',
        name: 'userclaim',
        component: () => import('../views/UserClaim/UserClaimList.vue')
    },
    {
        path: '/userclaimform/:id',
        name: 'userclaimform',
        component: () => import('../views/UserClaim/UserClaimForm.vue')
    }
    ,
    {
        path: '/userrole',
        name: 'userrole',
        component: () => import('../views/UserRole/UserRoleList.vue')
    },
    {
        path: '/userroleform/:id',
        name: 'userroleform',
        component: () => import('../views/UserRole/UserRoleForm.vue')
    }
    ,
    {
        path: '/usersetting',
        name: 'usersetting',
        component: () => import('../views/UserSetting/UserSettingList.vue')
    },
    {
        path: '/usersettingform/:id',
        name: 'usersettingform',
        component: () => import('../views/UserSetting/UserSettingForm.vue')
    },
    {
        path: '/vtransaction/:mediumtype',
        name: 'vtransaction',
        component: () => import('../views/VTransaction/VTransactionList.vue')
    },
    {
        path: '/consumptionvolume',
        name: 'consumptionvolume',
        component: () => import('../views/ConsumptionVolume/ConsumptionVolumeList.vue')
    },
    {
        path: '/consumptionvolumeform/:id',
        name: 'consumptionvolumeform',
        component: () => import('../views/ConsumptionVolume/ConsumptionVolumeForm.vue')
    },
    {
        path: '/activemandates',
        name: 'activemandateslistAll',
        component: () => import('../views/Mandate/ActiveMandatesList.vue')
    },
    {
        path: '/activemandates/:mediumtype',
        name: 'activemandateslist',
        component: () => import('../views/Mandate/ActiveMandatesList.vue')
    },
    {
        path: '/messagereading',
        name: 'messagereading',
        component: () => import('../views/MessageReading/MessageReadingList.vue')
    },
    {
        path: '/messagereadingform/:id',
        name: 'messagereadingform',
        component: () => import('../views/MessageReading/MessageReadingForm.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/Contact/ContactForm.vue')
    },
    {
        path: '/documents',
        name: 'documents',
        component: () => import('../views/Document/DocumentList.vue')
    },
    {
        path: '/:catchAll(.*)',
        name: "NotFound",
        component: () => import('../views/404.vue')
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router
