<template>
  <h2>{{ $t('headers.LastTransactions') }}</h2>
  <div class="sp-client-form-panel" style="padding:10px;">
    <el-table :data="transactions">
      <el-table-column property="ProductSymbol" :label="$t('fields.Product')" />
      <el-table-column property="Object_Type" :label="$t('fields.Object_Type')">
        <template #default="props">
          {{ props.row.ObjectTypeName }}
        </template>
      </el-table-column>
      <el-table-column property="Price" :label="$t('fields.Price')">
          <template #default="props">
              <span v-if="props.row.Object_Type && (props.row.Object_Type == 'PPA' || props.row.Object_Type == 'Offer')">
                  {{ Renderers.numberformat(props.row.Price,2, 3, ' ', ',') }}
              </span>
              <span v-if="props.row.Object_Type && props.row.Object_Type == 'Mandate' && props.row.Price == null">
                  <span v-if="props.row.ExpectedPrice">
                      {{ Renderers.numberformat(props.row.ExpectedPrice,2, 3, ' ', ',') }}
                  </span>
                  <span v-if="!props.row.ExpectedPrice">
                      ---
                  </span>
              </span>
              <span v-if="props.row.Object_Type && props.row.Object_Type == 'Mandate' && props.row.Price != null">
                  <span v-if="props.row.ExpectedPrice == null" :class="[props.row.RateInfo != null && props.row.RateInfo == true ? 'text-red' : '']">
                      {{ Renderers.numberformat(props.row.Price,2, 3, ' ', ',') }}
                  </span>
                  <el-popover v-if="props.row.ExpectedPrice != null" effect="light" trigger="hover" placement="top" width="auto">
                      <template #default>
                          <span>
                              {{ $t('fields.ExpectedPrice') }}: {{ Renderers.numberformat(props.row.ExpectedPrice,2, 3, ' ', ',') }}
                          </span>
                      </template>
                      <template #reference>
                          <span :class="[props.row.RateInfo != null && props.row.RateInfo == true ? 'text-red' : '']">
                              {{ Renderers.numberformat(props.row.Price,2, 3, ' ', ',') }}
                          </span>
                      </template>
                  </el-popover>
                  <el-popover v-if="props.row.RateInfo != null && props.row.RateInfo == true" placement="bottom" :width="350" trigger="click">
                      <template #reference>
                          <a href="#">
                              <span class="mdi mdi-information-outline mdi-18px text-red"
                                    style="position: absolute; margin-left: 50px"></span>
                          </a>
                      </template>
                      <h3>{{ $t('headers.information') }}</h3>
                      <el-divider></el-divider>
                      {{ $t('temp.RateTransactionInfo') }}
                  </el-popover>
              </span>
              <div v-if="props.row.Object_Type && (props.row.Object_Type == 'Tranche' || props.row.Object_Type == 'Complementary') ">
                  <span :class="[props.row.RateInfo != null && props.row.RateInfo == true ?  'text-red' : '']">
                      {{ Renderers.numberformat(props.row.Price,2, 3, ' ', ',') }}
                  </span>
                  <span v-if="props.row.RateInfo != null && props.row.RateInfo == true">
                      <el-popover placement="bottom" :width="350" trigger="click">
                          <template #reference>
                              <a href="#">
                                  <span class="mdi mdi-information-outline mdi-18px text-red"
                                        style="position: absolute; margin-left: 50px"></span>
                              </a>
                          </template>
                          <h3>{{ $t('headers.information') }}</h3>
                          <el-divider></el-divider>
                          {{ $t('temp.RateTransactionInfo') }}
                      </el-popover>
                  </span>
              </div>
          </template>
      </el-table-column>
      <el-table-column property="StatusId" :label="$t('fields.Status')" align="center">
        <template #default="props">
          <el-tag v-if="!Helpers.isNullOrEmpty(props.row.StatusId)" :type="props.row.StatusColor" class="mx-1" effect="dark" style="width: 100%">
            {{ props.row.StatusName }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import $ from 'jquery'
import { Helpers } from '@/scripts/helpers'
import { Renderers } from '@/scripts/renderers'

export default {
  name: 'DashboardTransactionListCmp',
  props: ['transactions', 'total'],
  mixins: [],
  setup() {
    return { Helpers, Renderers };
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  created() {

  },
  computed: {
  },
  methods: {

  },
  watch: {

  }
}
</script>
