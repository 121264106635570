﻿import axios from 'axios';
import * as base from '@/api/BaseApi';

export const getPage = (params, success, fail) => {
    return base.getRecords("/api/quotation/page", params, success, fail);
};

export const chartList = (params, success, fail) => {
    return base.getRecords("/api/quotation/chartList", params, success, fail);
};

export const getLatestByProduct = (params, success, fail) => {
    return base.getRecords("/api/quotation/getlatestbyproduct", params, success, fail);
};

export const get = (id, success, fail) => {
    return base.getRecord("/api/quotation/quotation", id, success, fail);
};

export const add = (model, success, fail) => {
    return base.add("/api/quotation/add", model, success, fail);
};

export const update = (model, success, fail) => {
    return base.update("/api/quotation/update", model, success, fail);
};
