﻿import axios from 'axios';
import * as base from '@/api/BaseApi';

export const getPage = (params, success, fail) => {
    return base.getRecords("/api/offer/page", params, success, fail);
};

export const get = (id, success, fail) => {
    return base.getRecord("/api/offer/offer", id, success, fail);
};

export const add = (model, success, fail) => {
    return base.add("/api/offer/add", model, success, fail);
};

export const update = (model, success, fail) => {
    return base.update("/api/offer/update", model, success, fail);
};

export const getOffersContracts = (contractIds, success, fail) => {
    var model = { searchModel: JSON.stringify({ ArrayOf_Id: contractIds }), pageIndex: 1, pageSize: 100 };
    return base.getRecords("/api/contract/page", model, success, fail)
};

export const offerAccept = (id, model, success, fail) => {
    return base.runOperation("/api/offer/OpOfferAccept", id, model, success, fail);
};

export const offerAcceptAuthorise = (id, model, success, fail) => {
    return base.runOperation("/api/offer/OpOfferAcceptAuthorise", id, model, success, fail);
};

export const offerAcceptAuthoriseCancel = (id, model, success, fail) => {
    return base.runOperation("/api/offer/OpOfferAcceptAuthoriseCancel", id, model, success, fail);
};

export const offerReject = (id, model, success, fail) => {
    return base.runOperation("/api/offer/OpOfferReject", id, model, success, fail);
};
