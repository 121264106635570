export const Configurations = {
    data() {
        return {
            pickerOptions: {

            },
            datePickerShortcuts: [
                {
                    text: this.$t('datePicker.today'),
                    value: new Date(),
                },
                {
                    text: this.$t('datePicker.lastDay'),
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        return date
                    },
                },
                {
                    text: this.$t('datePicker.weekAgo'),
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                        return date
                    },
                },
            ],
            datePickerRangeYearShortcuts: [
                {
                    text: this.$t('datePicker.previousYear'),
                    value: () => {
                        const end = new Date(new Date().getFullYear() - 1, 11, 31)
                        const start = new Date(new Date().getFullYear() - 1, 0, 1)
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.thisYear'),
                    value: () => {
                        const end = new Date(new Date().getFullYear(), 11, 31)
                        const start = new Date(new Date().getFullYear(), 0, 1)
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.nextYear'),
                    value: () => {
                        const end = new Date(new Date().getFullYear() + 1, 11, 31)
                        const start = new Date(new Date().getFullYear() + 1, 0, 1)
                        return [start, end]
                    },
                },
            ],
            datePickerRangeShortcuts: [
                {
                    text: this.$t('datePicker.lastDay'),
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.lastWeek'),
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.last2Weeks'),
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.lastMonth'),
                    value: () => {
                        var start = new Date();
                        const end = new Date(start.getFullYear(), start.getMonth(), 0);
                        start = new Date(start.getFullYear(), start.getMonth() - 1, 1);
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.last3Months'),
                    value: () => {
                        var start = new Date();
                        const end = new Date(start.getFullYear(), start.getMonth(), 0);
                        start = new Date(start.getFullYear(), start.getMonth() - 3, 1);
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.last6Months'),
                    value: () => {
                        var start = new Date();
                        const end = new Date(start.getFullYear(), start.getMonth(), 0);
                        start = new Date(start.getFullYear(), start.getMonth() - 6, 1);
                        return [start, end]
                    },
                },
            ],
            datePickerRangeShortcutsFuture: [
                {
                    text: this.$t('datePicker.nextDay'),
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() + 3600 * 1000 * 24 * 1)
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.nextMonth'),
                    value: () => {
                        var start = new Date();
                        const end = new Date(start.getFullYear(), start.getMonth() + 2, 0);
                        start = new Date(start.getFullYear(), start.getMonth() + 1, 1);
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.next3Months'),
                    value: () => {
                        var start = new Date();
                        const end = new Date(start.getFullYear(), start.getMonth() + 4, 0);
                        start = new Date(start.getFullYear(), start.getMonth() + 1, 1);
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.next6Months'),
                    value: () => {
                        var start = new Date();
                        const end = new Date(start.getFullYear(), start.getMonth() + 7, 0);
                        start = new Date(start.getFullYear(), start.getMonth() + 1, 1);
                        return [start, end]
                    },
                },
                {
                    text: this.$t('datePicker.nextYear'),
                    value: () => {
                        var start = new Date();
                        const end = new Date(start.getFullYear(), start.getMonth() + 13, 0);
                        start = new Date(start.getFullYear(), start.getMonth() + 1, 1);
                        return [start, end]
                    },
                },
            ],
            configuration: {
                showSearch: true,
                showSearchPanel: true,
                showExpandColumn: true,
                showSelectionColumn: true,
                showQuickSearch: true,
                showBreadcrumb: true,
                showBaseSearchPanel: true,
                showMainSearch: true,
            },
        };
    },
    methods: {
        disableFarDates: function (date) {
            return date < new Date(2020, 1, 1) || date > new Date(2030, 1, 1);
        },
    }

}