import { createI18n } from 'vue-i18n'
import en from '@/lang/en-GB.json'
import pl from '@/lang/pl-PL.json'
import { useCookies } from "vue3-cookies"
import axios from 'axios'
import localeEN from 'element-plus/es/locale/lang/en'
import localePL from 'element-plus/es/locale/lang/pl'
import * as api from '@/api/AccountApi';
import { Helpers } from "@/scripts/helpers";

const { cookies } = useCookies();

const supportedLanguages = ['en-GB', 'pl-PL'];

const messages = {
    "en-GB": en,
    "pl-PL": pl,
}

export const elementLocales = {
    "en-GB": localeEN,
    "pl-PL": localePL
}

export const elementLocale = function () {
    return elementLocales[defaultLanguage()];
}

export const defaultLanguage = function () {
    const langCookie = cookies.get("lang");

    // there is proper langauge cookie
    if (langCookie !== null
        && supportedLanguages.includes(langCookie)) {
        return langCookie;
    }
    //ep: komentuję bo zgodnie z wymaganiem defaultowy ma być język polski
    // // language from browser
    //else if (navigator.language && supportedLanguages.includes(navigator.language)) {
    //    return navigator.language
    //} 
    
    // default lang
    return 'pl-PL';
}

export const i18n = createI18n({
    locale: defaultLanguage(),
    globalInjection: true,
    messages,
    pluralizationRules: {
        'pl-PL': function (choice, choicesLength) {
            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        }
    }
})

export const { t } = i18n.global;

export const changeLanguage = function (lang) {
    i18n.global.locale = lang;
    cookies.set("lang", lang);
    axios.defaults.headers.common['accept-language'] = lang;
    api.changeLanguage(() => {}, () => {});
}

export const setUserLanguageCookie = function () {
    return new Promise((resolve) => {
        const langCookie = cookies.get("lang");

        api.getLanguage(
            (response) => {
                var lang = response?.data?.Model?.SettingValue
                if (!Helpers.isNullOrEmpty(lang) && supportedLanguages.includes(lang) && langCookie != lang) {
                    cookies.set("lang", lang);
                    axios.defaults.headers.common['accept-language'] = lang;
                    i18n.global.locale = lang;

                    resolve(lang);
                }
                else
                    resolve(defaultLanguage());
            },
            (error) => { resolve(defaultLanguage()) }
        );
    });
}
