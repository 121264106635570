﻿import axios from 'axios';
import * as base from '@/api/BaseApi';

export const getPage = (params, success, fail) => {
    return base.getRecords("/api/declarationkind/page", params, success, fail);
};

export const get = (id, success, fail) => {
    return base.getRecord("/api/declarationkind/declarationkind", id, success, fail);
};

export const add = (model, success, fail) => {
    return base.add("/api/declarationkind/add", model, success, fail);
};

export const update = (model, success, fail) => {
    return base.update("/api/declarationkind/update", model, success, fail);
};
