import * as api from '@/api/ClientLogApi';
import { defineStore } from 'pinia'

export const useClientLogStore = defineStore('ClientLogStore', {
    actions: {
        add(clientLog, success, fail) {
            api.add(clientLog,
                (response) => {
                    success && success(response)
                },
                (response) => {
                    fail && fail(response)
                })
        }
    }
});