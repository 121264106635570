﻿export const operationResult = Object.freeze({
    OK: 1,
    Warning: 0,
    Error: -1,
    Invalid: -2
});

export const tgeColors = Object.freeze({
    Lots: '#038eff',
    MinRate: '#2c2f72',
    DKR: '#75cee8',
    MaxRate: '#3d83d7',
    IndexPrice: '#384fc1',
    Filler: 'rgba(195, 195, 195, 0.3)',
});

export const chartPeriods = Object.freeze({
    TwoWeeks: '14d',
    OneMonth: '1m',
    ThreeMonths: '3m',
    SixMonths: '6m',
    Max: 'max'
});

export const productSwitchByYear = Object.freeze({
    TradingYear: 1,
    DeliveryYear: 0
});

export const operationExecutionType = Object.freeze({
    SYNC: 1,
    ASYNC: 2
});

export const fileUploadSettings = Object.freeze({
    maxFilesSizeSum: 10, //w MB
    maxFileSize: 1024 * 1024 * 10, //10MB w Bajtach
    maxFileNumber: 12,
    fileTypes: '.doc, .docx, .txt, .pdf, .png, .jpg, .jpeg, .gif, .xls, .xlsx, .csv' //muszą się zgadzać z appSettings.config FileAttachedAcceptedExtensions
});

export const permission = Object.freeze({
    ContractCreate: "eps.contract.create",
    ContractReadOwn: "eps.contract.read.own",
    ContractReadAll: "eps.contract.read.all",
    ContractWriteOwn: "eps.contract.write.own",
    ContractWriteAll: "eps.contract.write.all",
    ContractFinish: "eps.contract.finish",
    TrancheReadOwn: "eps.tranche.read.own",
    TrancheReadAll: "eps.tranche.read.all",
    TrancheWriteOwn: "eps.tranche.write.own",
    TrancheWriteAll: "eps.tranche.write.all",
    TrancheAddOwn: "eps.tranche.add.own",
    TrancheAddAll: "eps.tranche.add.all",
    MandateReadOwn: "eps.mandate.read.own",
    MandateReadAll: "eps.mandate.read.all",
    MandateWriteOwn: "eps.mandate.write.own",
    MandateWriteAll: "eps.mandate.write.all",
    MandateAddOwn: "eps.mandate.add.own",
    MandateAddAll: "eps.mandate.add.all",
    MandateCancel: "eps.mandate.cancel",
    PPAAdd: "eps.ppa.add",
    PPARead: "eps.ppa.read",
    TransactionReadOwn: "eps.transaction.read.own",
    TransactionReadAll: "eps.transaction.read.all",
    TransactionWriteOwn: "eps.transaction.write.own",
    TransactionWriteAll: "eps.transaction.write.all",
    UserReadAll: "eps.user.read.all",
    UserWriteAll: "eps.user.write.all",
    ApplicantContractModify: "eps.applicantcontract.modify",
    QuotationReadOwn: "eps.quotation.read.own",
    QuotationReadAll: "eps.quotation.read.all",
    QuotationWriteOwn: "eps.quotation.write.own",
    QuotationWriteAll: "eps.quotation.write.all",
    TradingIndexReadOwn: "eps.tradingindex.read.own",
    TradingIndexReadAll: "eps.tradingindex.read.all",
    TradingIndexWriteOwn: "eps.tradingindex.write.own",
    TradingIndexWriteAll: "eps.tradingindex.write.all",
    AlertReadOwn: "eps.alert.read.own",
    AlertReadAll: "eps.alert.read.all",
    AlertWriteOwn: "eps.alert.write.own",
    AlertWriteAll: "eps.alert.write.all",
    CalendarsReadOwn: "eps.calendars.read.own",
    CalendarsReadAll: "eps.calendars.read.all",
    CalendarsWriteOwn: "eps.calendars.write.own",
    CalendarsWriteAll: "eps.calendars.write.all",
    ProductReadOwn: "eps.product.read.own",
    ProductReadAll: "eps.product.read.all",
    ProductWriteOwn: "eps.product.write.own",
    ProductWriteAll: "eps.product.write.all",
    MessageReadOwn: "eps.message.read.own",
    MessageReadAll: "eps.message.read.all",
    MessageWriteOwn: "eps.message.write.own",
    MessageWriteAll: "eps.message.write.all",
    MessageMarkRead: "eps.message.mark.read",
    MessageDelete: "eps.message.delete",
    RunJobRead: "eps.runjob.read",
    RunJobStateRead: "eps.runjob.read",
    VReportPriceReadAll: "eps.reportprice.read.all",
    VReportTrancheDetailReadAll: "eps.reporttranchedetail.read.all",
    ParameterWriteOwn: "eps.parameter.write.own",
    ParameterWriteAll: "eps.parameter.write.all",
    OfferWriteOwn: "eps.offer.write.own",
    OfferWriteAll: "eps.offer.write.all",
    OfferAddAll: "eps.offer.add.all",
    OfferWriteInRealization: "eps.offer.write.inrealization",
    OfferWriteToVerify: "eps.offer.write.toverify",
    DeclarationLimitWriteOwn: "eps.declarationlimit.write.own",
    DeclarationLimitWriteAll: "eps.declarationlimit.write.all",
    DeclarationKindWriteOwn: "eps.declarationkind.write.own",
    DeclarationKindWriteAll: "eps.declarationkind.write.all",
    PowerLimitWriteOwn: "eps.powerlimit.write.own",
    PowerLimitWriteAll: "eps.powerlimit.write.all",
    UserActivityAll: "eps.useractivity.read.all",
    MigrateData: "eps.migrate.data",
    RolePermissionRead: "eps.rolepermission.read.all",
    ProductAccept: "eps.product.accept",
    MediumWrite:"eps.medium.write",
    MediumProductWriteOwn: "eps.mediumproduct.write.own",
    MediumProductWriteAll: "eps.mediumproduct.write.all",
    MediumProductTypeWriteOwn: "eps.mediumproducttype.write.own",
    MediumProductTypeWriteAll: "eps.mediumproducttype.write.all",
    TradingSessionPlanWriteOwn: "eps.tradingsessionplan.write.own",
    TradingSessionPlanWriteAll: "eps.tradingsessionplan.write.all",
});
