import $ from 'jquery'
import { t } from "@/setup/i18n-setup"
import { tgeColors, chartPeriods } from '@/scripts/const'
import moment from 'moment'
import { Notifications } from '@/scripts/notifications'
import { Logger } from '@/scripts/logger'
import * as api from '@/api/QuotationApi'

export const ChartTGEJs = {
    data() {
        return {
            chartTGEProps: {},
            chartTGEPeriod: this.chartPeriods.TwoWeeks,
            quotationsForProduct: [],
            dateRangeCalendarButtonType: '',
            selectedProduct: null, //zaznaczony rekord na liście notowań z pogrupowanymi produktami
            searchModelForProduct: {},

            TGEFilterDate: null,
        };
    },
    created() {

    },
    methods: {
        fetchChartTGEQuotationsForProduct: function (productSymbol) {
            if (!productSymbol) {
                this.quotationsForProduct = [];
                this.chartTGEProps = null;
                return;
            }

            let self = this;

            this.loading = true;

            this.quotationsForProduct = [];

            self.chartTGEProps = null;

            //warunki wyszukiwania
            this.searchModelForProduct.ProductSymbol = productSymbol;
            this.setChartTGEPeriodDates();

            api.chartList({
                searchModel: JSON.stringify($.extend(true, {}, this.searchModelForProduct)),
                pageIndex: 1,
                pageSize: 999
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        this.loading = false;
                        return;
                    }

                    this.quotationsForProduct = response.data.Model.data;

                    this.setChartTGEListProps(this.quotationsForProduct);

                    this.loading = false;
                },
                (error) => {
                    Logger.logException(error);

                    this.loading = false;
                }
            );
        },
        setChartTGEListProps: function (quotationsForProduct) {
            let self = this;

            var labelsChart = [];
            var datasetsChart = [
                {
                    kind: 'Lots',
                    label: t('chart.Lots'),
                    data: [],
                    backgroundColor: tgeColors.Lots,
                    order: 1,
                    type: 'bar',
                    yAxisID: 'y1',
                },
                {
                    kind: 'MinRate',
                    label: t('chart.MinRate'),
                    data: [],
                    borderColor: tgeColors.MinRate,
                    backgroundColor: tgeColors.MinRate,
                    type: 'line',
                    borderWidth: 1,
                    pointBorderWidth: 0,
                    pointStyle: 'rect',
                    order: 0,
                    yAxisID: 'y',
                },
                {
                    kind: 'DKR',
                    label: t('chart.DKR'),
                    data: [],
                    borderColor: tgeColors.DKR,
                    backgroundColor: tgeColors.DKR,
                    type: 'line',
                    borderWidth: 1,
                    pointBorderWidth: 0,
                    pointStyle: 'rect',
                    order: 0,
                    yAxisID: 'y',
                },
                {
                    kind: 'MaxRate',
                    label: t('chart.MaxRate'),
                    data: [],
                    borderColor: tgeColors.MaxRate,
                    backgroundColor: tgeColors.MaxRate,
                    type: 'line',
                    borderWidth: 1,
                    pointBorderWidth: 0,
                    pointStyle: 'rect',
                    order: 0,
                    yAxisID: 'y',
                    fill: { above: tgeColors.Filler, below: tgeColors.Filler, target: '-2' },
                },
                {
                    kind: 'IndexPrice',
                    label: t('chart.IndexPrice'),
                    data: [],
                    borderColor: tgeColors.IndexPrice,
                    backgroundColor: tgeColors.IndexPrice,
                    type: 'line',
                    borderWidth: 1,
                    pointBorderWidth: 0,
                    pointStyle: 'rect',
                    order: 0,
                    yAxisID: 'y',
                    hidden: true,
                },
            ];

            for (var i in quotationsForProduct) {
                var q = quotationsForProduct[i];

                labelsChart.push(q.QuoteDate.substring(0, 10));

                var dataLots = datasetsChart.filter(f => { return f.kind === 'Lots'; })[0];
                dataLots.data.push({ x: q.QuoteDate.substring(0, 10), y: q.Lots, type: "bar", kind: t('chart.Lots') });

                var dataMinRate = datasetsChart.filter(f => { return f.kind === 'MinRate'; })[0];
                dataMinRate.data.push({ x: q.QuoteDate.substring(0, 10), y: q.MinimumPrice, type: "line", kind: t('chart.MinRate') });

                var dataDKR = datasetsChart.filter(f => { return f.kind === 'DKR'; })[0];
                dataDKR.data.push({ x: q.QuoteDate.substring(0, 10), y: q.DailySettlementPrice, type: "line", kind: t('chart.DKR') });

                var dataMaxRate = datasetsChart.filter(f => { return f.kind === 'MaxRate'; })[0];
                dataMaxRate.data.push({ x: q.QuoteDate.substring(0, 10), y: q.MaximumPrice, type: "line", kind: t('chart.MaxRate') });

                var dataIndexPrice = datasetsChart.filter(f => { return f.kind === 'IndexPrice'; })[0];
                dataIndexPrice.data.push({ x: q.QuoteDate.substring(0, 10), y: q.IndexPrice, type: "line", kind: t('chart.IndexPrice') });
            };

            self.chartTGEProps = {
                labels: labelsChart,
                datasets: datasetsChart,
            };
        },

        setChartTGEPeriodDates: function () {
            if (this.searchModelForProduct.QuoteDateRange?.length == 2) {
                this.searchModelForProduct.QuoteDateFrom = this.searchModelForProduct.QuoteDateRange[0];
                this.searchModelForProduct.QuoteDateTo = this.searchModelForProduct.QuoteDateRange[1];
                return;
            };

            var referenceDate = this.TGEFilterDate ? moment(new Date(this.TGEFilterDate)) : moment();
            var result = { From: null, To: referenceDate };

            switch (this.chartTGEPeriod) {
                case this.chartPeriods.TwoWeeks:
                    result.From = moment(referenceDate).subtract(2, 'weeks');
                    break;
                case this.chartPeriods.OneMonth:
                    result.From = moment(referenceDate).subtract(1, 'months').startOf('month');
                    break;
                case this.chartPeriods.ThreeMonths:
                    result.From = moment(referenceDate).subtract(3, 'months').startOf('month');
                    break;
                case this.chartPeriods.SixMonths:
                    result.From = moment(referenceDate).subtract(6, 'months').startOf('month');
                    break;
                case this.chartPeriods.Max:
                    result.From = null;
                    break;
                default:
                    result.From = null;
                    result.To = null;
                    break;
            };

            this.searchModelForProduct.QuoteDateFrom = result.From;
            this.searchModelForProduct.QuoteDateTo = result.To;
        },
        onChangeChartTGEDateRange: function () {
            this.dateRangeCalendarButtonType = 'primary';
            this.chartTGEPeriod = null;

            $('#selectedDateRange').html(null);

            if (this.searchModelForProduct.QuoteDateRange && this.searchModelForProduct.QuoteDateRange[0] && this.searchModelForProduct.QuoteDateRange[1]) {
                var from = moment(this.searchModelForProduct.QuoteDateRange[0]).format("YYYY-MM-DD");
                var to = moment(this.searchModelForProduct.QuoteDateRange[1]).format("YYYY-MM-DD");
                $('#selectedDateRange').html(from + " - " + to);
            };

            if (this.selectedProduct)
                this.fetchChartTGEQuotationsForProduct(this.selectedProduct.ProductSymbol);
        },
        onChartTGEPeriodChange: function () {
            this.dateRangeCalendarButtonType = '';
            this.searchModelForProduct.QuoteDateRange = null;
            $('#selectedDateRange').html(null);

            this.setChartTGEPeriodDates();

            if (this.selectedProduct)
                this.fetchChartTGEQuotationsForProduct(this.selectedProduct.ProductSymbol);
        },
        onChartTGEFilterDateChange: function (newDate) {
            this.TGEFilterDate = newDate;

            if (this.searchModelForProduct.QuoteDateRange
                && this.searchModelForProduct.QuoteDateRange[0]
                && this.searchModelForProduct.QuoteDateRange[1]) {
                //
            }
            else {
                this.onChartTGEPeriodChange();
            };
        },
    },
};