import axios from 'axios';

const add = (log, success, fail) => {
    axios
        .post("/api/clientLog/add", log,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

        )
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export { add };