import { t, defaultLanguage } from "../setup/i18n-setup"
import { Helpers } from "./helpers"
import moment from 'moment'

export const Renderers = {
    /**
     * Format bool value as string with language support
     * @param value - bool
     */
    yesNo(value) {
        if (value === true || value === 1) {
            return t('consts.yes');
        }
        else if (value === false || value === 0) {
            return t('consts.no');
        }
        else {
            return "";
        };
    },
    /**
     * Format string to shorter string with ellipsis at the end
     * @param str - string, source string
     * @param limit - int, char limit before ellipsis sign
     */
    ellipsis: function (str, limit) {
        if (str) {
            var len = str.toString().length;
            if (len > limit) {
                return str.substring(0, limit) + '...';
            }
            else {
                return str;
            }
        }
    },
    /**
     * Format amount as default numberFormat, ex. 1234,5567 -> 1 234,56
     * @param number - {int, float, string}, source number value
     */
    amountFormat: function (number) {
        return this.numberformat(number, 2, 3, ' ', ',');
    },
    /**
     * Format number with decimal places and separators
     * @param number - {int, float, string}, source number
     * @param decimalPlaces - int, length of desired decimal places
     * @param numbersCountBetweenSeps - int, length of thousand group, default 3
     * @param sepThousands - string, thousand separator, in polish is space ' ', default is comma ','
     * @param sepTens - string, decimal separator
     */
    numberformat: function (number, decimalPlaces, numbersCountBetweenSeps, sepThousands, sepTens) {
        if (number != null && number != undefined) {
            number = parseFloat(number);

            var re = '\\d(?=(\\d{' + (numbersCountBetweenSeps || 3) + '})+' + (decimalPlaces > 0 ? '\\D' : '$') + ')';
            var num = number.toFixed(Math.max(0, ~~decimalPlaces));
            var a = (sepTens ? num.replace('.', sepTens.toString()) : num);
            var b = a.replace(new RegExp(re, 'g'), '$&' + (sepThousands || ','));
            return b;
        }
        else {
            return '';
        };
    },
    /**
     * Format string date to output date in given format
     * @param str - string, source date string
     * @param fmt - string, target date format, default 'yyyy-MM-DD'
     */
    formatAsDateFromProc(str, fmt) {
        if (Helpers.isNullOrEmpty(fmt)) fmt = 'yyyy-MM-DD';
        if (Helpers.isNullOrEmpty(str)) return '';

        var isDateType = str instanceof Date;
        if (isDateType) {
            return moment(str).format(fmt);
        };

        if (typeof str.indexOf !== 'function') {
            console.error('formatAsDateFromProc str.indexOf !== function'); //todo:prod
            return '';
        }

        var date = str;

        var isJsonDane = str.indexOf('/Date') >= 0;
        if (isJsonDane) {

            console.error('isJsonDane!'); //todo:prd

            var dateTmp2 = parseInt(str.replace('/Date(', '').replace(')/', ''));
            //var dateTmp = new Date(dateTmp2);
            //dateTmp = new Date(dateTmp.getFullYear(), dateTmp.getMonth(), dateTmp.getDate(), dateTmp.getUTCHours(), dateTmp.getMinutes(), dateTmp.getSeconds());
            date = dateTmp2;
            //date = dateTmp;

            //date = moment.utc(dateTmp2); //jeśli rozwiązanie wyżej nie zabezpieczy strefy i przesunie godziny
        };

        if (str.indexOf('Z') >= 0) {
            date = moment.utc(str);
        };

        return moment(date).format(fmt);
    },
    /**
     * TO EXTEND
     * Format date difference as string in second
     * @param start - date, source start date object
     * @param end - date, source end date object
     */
    diffTime: function (start, end) {
        //wyliczenie różnicy pomiędzy podanymi datami i przedstawienie w formacie 12 sek.

        if (!end)
            return ''; //todo

        var diff = (new Date(end) - new Date(start)) / 1000;

        var diffDec = parseFloat(diff).toFixed(2);

        //console.log(start, end, diffDec);

        return diffDec.toString() + ' s.';
    },

    // *************************************************************************************************************************************************
    // EPS 
    // *************************************************************************************************************************************************

    mediumNames: function (mediums) {
        if (Helpers.isNullOrEmpty(mediums))
            return '';

        var result = [];
        var tab = mediums.split(',');
        for (var i in tab) {
            switch (tab[i]) {
                case "Energia":
                case "Energy":
                case "EE":
                    result.push(t('menu.energy'));
                    break;
                case "Gaz":
                case "Gas":
                case "GAS":
                case "G":
                    result.push(t('menu.gas'));
                    break;
                case "Prawa majątkowe":
                case "Property Rights":
                case "PM":
                case "PR":
                    result.push(t('menu.pm'));
                    break;
            };
        };

        return result.join(', ');
    },

    /**
     * MOCK
     * Format medium as icon
     * @param medium - string, medium value
     */
    mediumIcon: function (medium) {
        switch (medium) {
            case "EE":
                return 'flash';
            case "GAS":
                return 'fire';
            case "PR":
                return 'file-document-edit-outline';
        };
    },

    mediumName: function (medium) {
        switch (medium) {
            case "EE":
                return t('menu.energy');
            case "GAS":
                return t('menu.gas');
            case "PR":
                return t('menu.pm');
        };
    },

    /**
     * Format 
     * @param firstName - string
     * @param lastName - string
     */
    personFullName: function (firstName, lastName) {
        if (!firstName || !lastName)
            return "";
        return firstName + " " + lastName;
    },

    /**
     * Get color by percent
     * @param percent - number
     */
    percentColor: function (percent) {
        if (percent < 20.00)
            return 'var(--enea-color-darkred)';
        else if (percent < 40.00)
            return '#ff5900';
        else if (percent < 60.00)
            return 'var(--el-color-warning)';
        else if (percent < 80.00)
            return '#ffe003';
        else if (percent < 100.00)
            return '#aee400';
        else
            return 'var(--el-color-success)';
    },

    declarationKindNames: function (declarationKinds) {
        if (declarationKinds.length == 0)
            return '';

        var result = [];
        for (var kind of declarationKinds) {
            switch (kind) {
                case "T":
                    result.push(t('productKind.Tranche'));
                    break;
                case "M":
                    result.push(t('productKind.Mandate'));
                    break;
                case "O":
                    result.push(t('productKind.ENEAOffer'));
                    break;
                case "P":
                    result.push(t('productKind.PPA'));
                    break;
            };
        };

        result = Array.from(new Set(result));

        return result.join(', ');
    },

    sliceString: function (text, sliceNr, appendText) {

        var result = '';

        if (text)
            result = text;

        if (!sliceNr)
            sliceNr = 5;

        if (result.length > sliceNr)
            result = result.slice(0, sliceNr);

        if (appendText)
            result = result + ' ' + appendText;

        return result;
    },

    textWithFootnote: function (text, footnote) {
        let result = text ? text : "";
        if (footnote) {
            if (result)
                result += "<br>";
            result = `${result}<div style="font-size: 10px; line-height: 0.95; padding-left: 2px;">${footnote}</div>`;
        }
        return result;
    },

    /*
        Return responsive size for el-dialog
        @params - string, percent size for resolution graterThen pixels (gtXXX) or lower than pixels (lt), ex. '45%'
    */
    dialogSize: function (gt1920, gt1200, gt992, gt768, gt500, lt500, elseValue) {
        if (window.innerWidth < 500)
            return lt500 ?? '95%';
        else if (window.innerWidth >= 1920)
            return gt1920 ?? '30%';
        else if (window.innerWidth >= 1200)
            return gt1200 ?? '40%';
        else if (window.innerWidth >= 992)
            return gt992 ?? '60%';
        else if (window.innerWidth >= 768)
            return gt768 ?? '70%';
        else if (window.innerWidth >= 500)
            return gt500 ?? '80%';
        else
            return elseValue ?? '70%';
    },
    /*
        Return responsive size with px
        @params - string, pixel size for resolution graterThen pixels (gtXXX) or lower than pixels (lt), ex. '450px'
    */
    pixelSize: function (gt1920, gt1200, gt992, gt768, gt500, lt500, elseValue) {
        var result = this.numberSize(gt1920, gt1200, gt992, gt768, gt500, lt500, elseValue);
        result = result + 'px';
        return result;
    },
    /*
        Return responsive size - only number
        @params - string, pixel size for resolution graterThen pixels (gtXXX) or lower than pixels (lt), ex. '450'
    */
    numberSize: function (gt1920, gt1200, gt992, gt768, gt500, lt500, elseValue) {
        var result = 400;

        if (window.innerWidth < 500)
            result = lt500 ?? 500;
        else if (window.innerWidth >= 1920)
            result = gt1920 ?? 500;
        else if (window.innerWidth >= 1200)
            result = gt1200 ?? 500;
        else if (window.innerWidth >= 992)
            result = gt992 ?? 500;
        else if (window.innerWidth >= 768)
            result = gt768 ?? 500;
        else if (window.innerWidth >= 500)
            result = gt500 ?? 500;
        else
            result = elseValue ?? 500;

        return result;
    },
}
