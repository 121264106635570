import { operationResult } from "./const"
import { ElNotification, ElMessageBox } from 'element-plus'
import { Helpers } from './helpers'
import { Renderers } from './renderers'
import { t } from "../setup/i18n-setup"

export const Notifications = {
    /**
  * Show message
  * @param {any} title - message title
  * @param {any} subtitle - message subtitle
  * @param {any} description - message text (description)
  * @param {any} isAlert - true if show ElMessage, false if show ElNotification
  * @param {any} type - message type ('success', 'warning', 'info', 'error', etc.)
  * @param {any} error - error object / array of errors
  */
    showMsg(title, subtitle, description, isAlert, type, error, callback) {
        if (!Helpers.isNullOrEmpty(subtitle))
            subtitle = '<b>' + subtitle + ":</b>";

        var message = this.getErrorInfo(error);

        if (!description
            || description == null
            || description == undefined
            || description == '') {
            description = message;
        };

        if (isAlert) {
            var isList = description.indexOf('n/g') >= 0;
            const html = isList ? '<div>' + subtitle + '<ul><li>' + description.replace(/\/n/g, "</li><li>") + '</li></ul></div>' : description;

            ElMessageBox.alert(html, title, {
                dangerouslyUseHTMLString: true,
                type: type ? type : 'success',
                callback: (action) => {
                    callback && callback(action);
                }
            });
        }
        else {
            ElNotification({
                title: title,
                message: description,
                type: type ? type : 'success',
                dangerouslyUseHTMLString: true
            });
        };
    },

    /**
        * Show notification of Result<Model>
        * @param {any} result - Result<Model> from API
        * @param {any} isAlert - true if show ElMessage, false if show ElNotification
        * @param {any} showCorrect - show message even if result is OK
        * @param {any} allowNullModel - when false: show message when result is OK but model is NULL
        * @param {any} customMessage - custom message if needed
        * @param {any} callback - callback function
    */
    showOperationMessage(result, isAlert, showCorrect = false, allowNullModel = false, customMessage = null, callback = null) {
        // do not show OK result and allow empty model
        if (result.Status === operationResult.OK
            && !showCorrect && allowNullModel) {
            return true;
        }
        // do not show OK result but do not allow empty model
        else if (result.Status === operationResult.OK
            && !showCorrect && !allowNullModel && result.Model) {
            return true;

        }
        // do not allow empty model and model is empty :)
        else if (result.Status === operationResult.OK
            && !allowNullModel && result.Model == null) {
            this.showMsg(
                t('messages.Error'),
                null,
                customMessage ?? t('error.NullModelException'),
                isAlert,
                'error',
                null,
                callback
            );

            return false;
        }
        // result ok and show correct
        else if (result.Status === operationResult.OK && showCorrect) {
            this.showMsg(
                t('messages.Success'),
                null,
                customMessage ?? t('messages.Success'),
                isAlert,
                'success',
                null,
                callback
            );

            return true;
        }
        // other results
        else if (result.Status !== operationResult.OK) {
            this.showMsg(
                result.Status === operationResult.Error
                    ? t('messages.Error') : t('messages.Warning'),
                null,
                null,
                isAlert,
                customMessage ?? this.getOperationStatusLabel(result.Status),
                result,
                callback
            );

            return false;
        }
    },
    /**
        * Show alert if action is forbidden for user
        * @param {any} customMessage - custom message if needed
        * @param {any} callback - callback function after error 403
    */
    showForbiddenMessage(customMessage = null, callback = null) {
        this.showMsg(
            t('messages.Error'),
            null,
            customMessage ?? t('error.ForbiddenException'),
            false,
            'error',
            null,
            callback
        );
    },

    /**
     * Convert operation status to label type
     * @param {any} operationResultStatus - operation result status
     */
    getOperationStatusLabel(operationResultStatus) {
        switch (operationResultStatus) {
            case operationResult.OK:
                return 'success';
            case operationResult.Warning:
                return 'warning';
            default:
                return 'error';
        }
    },

    //create object contains raw, short and extended error message; used in operations summary
    prepareErrorMessage: function (error) {
        var msg = {
            String: '',
            Short: t('messages.Error'),
            CompleteHtml: ''
        };

        if (typeof (error) !== 'undefined') {
            //powinniśmy otrzymać zawsze Result z właściwością MessagesString; jeśli go nie ma - otrzymaliśmy stronę błędu
            if (typeof (error.MessagesString) == 'undefined') {
                throw new Error(t('messages.CatchErrorAjax'));
            };

            msg.String = error.MessagesString;

            if (error.Messages.length == 1) {
                msg.Short = Renderers.ellipsis(error.Messages[0].Message, 50)
            }
            else if (error.Messages.length > 1) {
                msg.Short = t('messages.Errors') + ' (' + error.Messages.length + ')';
            };

            for (var i in error.Messages) {
                msg.CompleteHtml += '<p>' + error.Messages[i].Message + '</p>';
            };
        };

        return msg;
    },
    /**
     * Get error message from error object / array of errors
     * @param {any} error - error object / array of errors
     */
    getErrorInfo(error) {
        if (error === null)
            return null;

        var rawError = '';
        var messageForUser = '';
        var defaultMessage = t('messages.Error');

        if (typeof (error) !== 'undefined') {
            rawError
                = ((error.response && error.response.data) ? error.response.data.ExceptionMessage ? error.response.data.ExceptionMessage : error.response.data.Message : error.message);

            if (typeof (error) === 'string') {
                messageForUser = error;
            }
            else if (typeof (error.MessagesString) != 'undefined') {
                messageForUser = '<div><ul><li>' + error.MessagesString.replace(/\/n/g, "</li><li>") + '</li></ul></div>';
            }
            else if (error?.response?.status === 403) {
                messageForUser = t('error.ForbiddenException');
            }
            else {
                messageForUser = defaultMessage;
            };
        };

        return messageForUser;
    }
};