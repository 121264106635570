import { Configurations } from '../scripts/configuration'
import { Operations } from '../scripts/operations'
import { Common } from '../scripts/common'
import { Dictionaries } from '../scripts/dictionaries'
import { Helpers } from '../scripts/helpers'
import { Renderers } from '../scripts/renderers'
import $ from 'jquery'
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

export const List = {
    mixins: [Operations, Configurations, Common, Dictionaries],
    data() {
        return {
            //mock
            listOperations: [],
            currentPage: 1,
            loadingList: false,
            //page settings
            pageName: '',
            pageObjectName: '',
            selectedRows: [],
            isSelectedAnyRow: false,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100],
            tableData: [],
            ghostRowDropVisible: true, //pokazuje wiersz przy przesuwaniu (true), przy (false) można używać jako np. przesuwania pliku do folderu, nie rozsuwa wtedy tabeli na nowe miejsce dla wiersza
            expandKeys: [],

            // return false; — for cancel
            // return -1; — insert before target
            // return 1; — insert after target
            // return true; — keep default insertion point based on the direction
            // return void; — keep default insertion point based on the direction
            rowDropMoveReturn: -1,

            setViewSelected: this.$t('toolbar.all'), //default main filter dropdown value
            searchModel: {},
            searchModelCopy: {},
            searchModelInfo: {},
            currentRow: null,
            showFilters: false,
            pageIndex: 1,
            total: 0,
            model: null,
            tableHeight: 100,
            minTableHeight: 160,

            settingsSaveAvailable: true,
            columnListVisibility: [],
            copyColumnListVisibility: [],
            orderColumns: {},
            widthColumns: {},
            isUserSettingsLoaded: false,

            //loadingi
            loading: false,
            loadingRow: false,
            renderRow: true,
        };
    },
    methods: {
        //table view
        tableRowClassName: function (row) {

        },
        //używać tylko na pełnych listach (całostronicowych),
        //zakomentować na listach ładowanych jako element strony, np. w tabie formularza z nagłówkiem albo z wykresem
        setTableHeight: function (e, topElement, buffer) {
            if (this.parent && this.parent.pageName + 'Cmp' != this.pageName) {
                return; //jeśli strona nie jest ładowana całostronnie to nie przeliczaj
            };

            var bufferHeight = buffer ? buffer : 110;
            var relativeElement = topElement ? topElement : '.table.mar-t-15.table-condensed';
            var windowHeight = window.innerHeight;
            //console.log('windowHeight', windowHeight);

            var rel = $(relativeElement)[0];
            var jrel = $(rel);
            var positionOfTableHeader = jrel.offset() ? jrel.offset().top : rel ? rel.offsetTop : 100;

            // console.log('windowHeight', windowHeight);
            // console.log('relativeElement', relativeElement);
            // console.log('bufferHeight', bufferHeight);
            // console.log('positionOfTableHeader', positionOfTableHeader);

            //this.$root.isMobile
            if (windowHeight < 450) {
                return; //pełnowymiarowa tabela, nie ustawiamy wysokości
            };

            // console.log('minTableHeight', this.minTableHeight);

            var tableHeightToSet = windowHeight - positionOfTableHeader - bufferHeight;
            this.tableHeight = tableHeightToSet > this.minTableHeight ? tableHeightToSet : this.minTableHeight;

            // console.log('tableHeight', this.tableHeight);
        },
        //ustawienie wysokości tabeli na zasadzie :height="setHeight('table_id', 200)"
        setHeight: function (tableId, heightPx) {
            $('#' + tableId).find('.el-table__body-wrapper').css('height', heightPx + "px");
        },

        //copy table content
        selectElementContents: function (id, e) {
            let self = this;
            var el = document.getElementById(id);

            //obsługa kolumn fixed; przed kopiowaniem je chowamy i pokazujemy domyślne
            $(el).find('.is-hidden').removeClass('is-hidden').addClass('is-hidden-copyseleced');
            $('.el-table__fixed').hide();

            var body = document.body, range, sel;

            if (document.createRange && window.getSelection) {
                range = document.createRange();
                sel = window.getSelection();
                sel.removeAllRanges();
                try {
                    range.selectNodeContents(el);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(el);
                    sel.addRange(range);
                }
            } else if (body.createTextRange) {
                range = body.createTextRange();
                range.moveToElementText(el);
                range.select();
            }

            document.execCommand("copy");
            document.getSelection().removeAllRanges();

            this.$message({
                message: self.$t('messages.TableCopied'),
                type: 'success',
                duration: 2000,
                offset: 200
            });

            //obsługa kolumn fixed; po kopiowaniu je pokazujemy i chowamy domyślne
            $(el).find('.is-hidden-copyseleced').removeClass('is-hidden-copyseleced').addClass('is-hidden');
            $('.el-table__fixed').show();
        },

        //selection
        handleSelectionChange(rows) {
            this.selectedRows = rows;
            this.isSelectedAnyRow = rows.length > 0;
        },

        //operations
        handleCommand(command, row, index) {
            if (command && row) {
                this.selectedRows = [row];
                this.prepareCurrentOperation(command);
                // this.selectedRows = [row];
                // this.operation = this.operations[command];
                // this.operationModel = this.operation.Model;
                // if (this.operation) {
                //     if (this.operation.IsConfirmed || this.operation.IsExtended) {
                //         this.showDlgOperation = true;
                //     }
                // }
            }
        },

        //rowExpander
        handleExpandChange(row, expanded, tableRef, propertyName) {
            var singleExpand = true;

            if (!propertyName)
                propertyName = 'Id';

            if (singleExpand) {
                if (this.expandKeys?.indexOf(row[propertyName]) >= 0) {
                    this.expandKeys = null;
                    this.currentRow = null;
                    return;
                };

                this.expandKeys = [];
            };

            if (this && tableRef && this.$refs && this.$refs[tableRef] && row)
                this.$refs[tableRef].setCurrentRow(row);

            if (!singleExpand) {
                if (this.expandKeys.indexOf(row[propertyName]) >= 0) {
                    this.expandKeys = this.expandKeys.filter(function (value, index, arr) {
                        return value != row[propertyName];
                    });
                    this.currentRow = null;
                    return;
                }
            };

            this.currentRow = row;
            this.fetchData(row.Id, row);

            if (expanded.length > 1) {
                expanded.shift()
            };
        },

        //sorting
        sortChange(sortProps) {
            if (sortProps.prop == null) {
                this.searchModel.Sort = null;
                this.fetchList();
                return;
            };

            var sort = {};
            var order = "ASC";
            if (sortProps.order === "descending")
                order = "DESC";
            else
                order = "ASC";
            sort[sortProps.prop] = order;
            this.searchModel.Sort = sort;
            this.fetchList();
        },

        //paging
        pageIndexChange: function (pageIndex) {
            this.pageIndex = pageIndex;
            this.fetchList();
        },
        sizeChange: function (pageSize) {
            this.pageSize = pageSize;
            this.fetchList();
        },

        //searching and filtering
        toggleFilters: function (e) {
            this.showFilters = !this.showFilters;
        },
        getSearchFormValues: function () {
            //ustawienie informacji po czym odpalono wyszukiwanie na podstawie parsowania formularza searchModelu

            let self = this;

            return; //todo:ep

            var formSearcher = $('.d_searchModelInfo');
            if (formSearcher.length > 0) {
                self.searchModelInfo = [];

                var fields = self.$refs.searchPanel.getFields();
                $.each(self.searchModel, function (e, i) {
                    var value = self.searchModel[e];

                    if ((value != null && value != '') || value == 0) {
                        var field = fields.filter(obj => {
                            return obj.prop === e;
                        });

                        switch (typeof value) {
                            case 'number':
                                //todo filtr boolean
                                break;
                            case 'boolean':
                                value = value == true ? self.$t('consts.yes') : self.$t('consts.no');
                                break;
                            case 'object':
                                if (value instanceof Date) {
                                    try {
                                        var tryDate = new Date(value);
                                        var isDate = !isNaN(tryDate.getTime());
                                        if (isDate)
                                            value = Renderers.formatAsDateFromProc(tryDate);
                                    }
                                    catch (ex) {
                                        //topimy
                                    };
                                }
                                else if (value.Key && value.Name) {
                                    value = value.Name;
                                };
                                break;
                        };

                        var valuePresented = value;

                        if (field && field.length > 0) {
                            var label = field[0].label;

                            //dla rangedate
                            if (!field[0].$children || field[0].$children.length === 1) {
                                //
                            }
                            //dla select
                            else if (field[0].$children[1].selected) {
                                try {
                                    if (field[0].$children && field[0].$children.length > 1) {

                                        var selectedOption = field[0].$children[1].options.filter(obj => {
                                            return obj.value === value;
                                        });

                                        valuePresented = " " + selectedOption[0].label;
                                    };
                                }
                                catch (ex) {
                                    console.error(ex);
                                }
                            }
                            //dla cascader
                            else if (field[0].$children[1].presentText == null
                                || (field[0].$children[1].presentText && field[0].$children[1].presentText != null)) {
                                try {

                                    //console.log(field[0].$children[1]);

                                    var deepObj = self.searchDeep(field[0].$children[1].options, value);
                                    valuePresented = deepObj[0].label;
                                }
                                catch (ex) {
                                    //console.error(ex);
                                }
                            };

                            self.searchModelInfo.push({ fieldName: label, fieldValue: valuePresented });
                        }
                        //pola wyszukiwania dla których nie ma pól formularza
                        else {
                            //nie prezentujemy bo nie mamy czytelnej labelki, można dodać pole formularza z prop i label i klasą hide, wtedy się pokaże
                            //self.searchModelInfo.push({ fieldName: e, fieldValue: valuePresented });
                        };
                    };
                });
            };
        },
        clearAdditionalFilters() {
            //dodatkowe pola na stronie, np. wyliczane, albo daterange, etc.
        },
        clearFilters() {
            if (this.$refs['searchPanel']) {
                this.$refs['searchPanel'].resetFields();
            };

            this.searchModel.QuickSearchTerm = null;

            this.clearAdditionalFilters();

            this.fetchList();
        },

        //sortable, move columns and rows
        setDraggableTh: function () {
            //ustawienie draggable dla każdej kolumny
            var ths = $('.el-table__header th');
            $.each(ths, function (i, j) {
                ($(j)[0]).draggable = true;
            });
        },
        onRowDrop(evt) {
            //this.tableData musi być ustawiona na liście przy fetchlist na listę obiektów otrzymanych w reponse po kompletnym przetworzeniu

            var sourceRow = evt.target.rows[evt.newIndex];
            var targetRow = evt.target.rows[evt.newIndex + 1];

            //console.log('onRowDrop evt', evt);
            //console.log('onRowDrop targetRow', targetRow);
            //console.log('onRowDrop sourceRow', sourceRow);

            //console.log('tableData source', this.tableData[evt.oldIndex].Title);
            //console.log('tableData target', this.tableData[evt.newIndex].Title);

            //evt.target.rows[evt.newIndex].remove(); //usunięcie wiersza z bieżącej tabeli w DOM

            //const currRow = this.tableData.splice(evt.oldIndex, 1)[0];
            //this.tableData.splice(evt.newIndex, 0, currRow);
        },
        rowDrop() {
            let self = this;
            const tbody = document.querySelector('.el-table__body-wrapper tbody');

            Sortable.create(tbody, {
                ghostClass: self.ghostRowDropVisible === false ? "sortable-ghost-displayNone" : "sortable-ghost",
                //draggable: 'tr',
                onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                    var expandedRow = $('.el-table__expand-icon--expanded').length;
                    if (expandedRow > 0) {
                        return false; //nie działa z rowiniętym wierszem, więc blokujemy
                    }

                    return self.rowDropMoveReturn;
                },
                onEnd: evt => {
                    this.onRowDrop(evt);
                },
                onChange: function (/**Event*/evt) {

                }
            });
        },
        initializeDragAndDropFunctionality(table) {
            const tableColumn = $(table.$el).find('.el-table__header-wrapper .el-table__header thead tr')[0];

            Sortable.create(tableColumn, {
                draggable: 'th',
                onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                    var expandedRow = $('.el-table__expand-icon--expanded').length;
                    if (expandedRow > 0) {
                        return false; //nie działa z rowiniętym wierszem, więc blokujemy
                    }
                },
                onEnd: evt => {
                    this.dragReorderColumn(evt, table);
                }
            });
        },
        dragReorderColumn(evt, table) {
            function swap(draggableSelector, movedElement, newIndex, oldIndex) {
                const parent = $($(movedElement)[0].parentNode)[0];
                const cells = $(parent).find(draggableSelector);

                //var width = evt.item.attributes['data-width'];
                //console.log(width, $(evt.item).width());

                if (oldIndex > newIndex) {
                    parent.insertBefore(movedElement, cells[newIndex]);
                } else {
                    // inserts after trs[oldIndex] - if nextSibling is null insertBefore puts item to the end
                    parent.insertBefore(movedElement, cells[newIndex].nextSibling);
                }
            };

            var expandedRow = $('.el-table__expand-icon--expanded').length;
            if (expandedRow > 0) {
                return; //nie działa z rowiniętym wierszem, więc blokujemy
            }
            else {
                const tmpStorage = {};

                swap('th', evt.item, evt.newIndex, evt.oldIndex);

                const tableColumn2 = document.querySelectorAll(
                    '.el-table__body-wrapper .el-table__body tr'
                );

                $.each(tableColumn2, function () {
                    var item = $(this)[0].children[evt.oldIndex];
                    swap('td', item, evt.newIndex, evt.oldIndex);
                });

                const tableColGroup = $(table.$el).find('.el-table__header colgroup');
                var item = $(tableColGroup)[0].children[evt.oldIndex];
                swap('col', $(item)[0], evt.newIndex, evt.oldIndex);
                const tableColGroupTr = document.querySelectorAll(
                    '.el-table__body-wrapper .el-table__body colgroup'
                );
                $.each(tableColGroupTr, function () {
                    var item = $(this)[0].children[evt.oldIndex];
                    swap('col', $(item)[0], evt.newIndex, evt.oldIndex);
                });

                tmpStorage.onChange = undefined;

                const tableHeader = document.querySelectorAll('.el-table__header-wrapper .el-table__header tr');
                var orderColumns = {};

                if (tableHeader[0] === undefined) { return; };

                for (var h = 0; h < tableHeader[0].children.length; h++) {
                    var item2 = tableHeader[0].children[h];
                    if (!$(item2).hasClass('gutter')) {
                        $(item2).attr('data-indexorder', h);
                        orderColumns[$(item2).data().prop] = h;
                    };
                };

                this.orderColumns = orderColumns;
                //console.log('change order', orderColumns);

                //sortowanie kolumn w menu
                var self = this;
                self.copyColumnListVisibility.sort(function (a, b) {
                    var aName = self.orderColumns[a.column];
                    var bName = self.orderColumns[b.column];
                    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
                });

                //this.settingsSaveAvailable = false;

                //ustawienie draggable dla każdej kolumny
                this.setDraggableTh();

                localStorage.setItem(this.pageName + '_ColumnOrder', JSON.stringify(orderColumns));
            }
        },
        dragend: function (newwidth, oldWidth, column, event) {
            //console.log('dragend', newwidth, oldWidth, column, event);

            this.setDraggableTh();
        },

        //configuration menu
        showListConfiguration: function () {
            $('.right-sidebar').addClass('right-sidebar-open');
        },
        loadSettingsMenu: function (menuSettings) {
            if (menuSettings !== undefined) {
                menuSettings.fetchData();
            }
            else {
                let self = this;

                // require(['settings-cmp'], function (cmp) {

                //     var props = {
                //         parent: self
                //     };

                //     menuSettings = Vue.createApp(cmp, props);

                //     menuSettings.use(ElementPlus, { locale: ElementPlus.lang[appLocalization] });
                //     menuSettings.use(i18n);
                //     menuSettings.mount("#listSettings-placeholder");
                // });
            };

            for (var cc in this.columnListVisibility) {
                var obj = jQuery.extend(true, {}, this.columnListVisibility[cc]);
                this.copyColumnListVisibility.push(obj);
            };

            var self = this;
            self.copyColumnListVisibility.sort(function (a, b) {
                var aName = self.orderColumns[a.column];
                var bName = self.orderColumns[b.column];
                return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
            });

            return menuSettings;
        },
        makeSortableSettings: function () {
            var self = this;
            const tbody = document.querySelector('#right-sidebar-list-tab .el-form');
            if (tbody !== null) {
                Sortable.create(tbody, {
                    onEnd({ newIndex, oldIndex }) {
                        self.settingsSaveAvailable = false;

                        //var domElements = $('#right-sidebar-list-tab .el-form .el-row[data-column]');

                        //var visibilityOrder = [];
                        //for (var d = 0; d < domElements.length; d++) {
                        //    var domEl = $(domElements[d]);
                        //    var el = domEl[0].dataset;
                        //    var obj = {};
                        //    obj.column = el.column;
                        //    obj.value = el.visible === "true" || el.visible === true;
                        //    obj.name = el.name;
                        //    visibilityOrder.push(obj);
                        //};

                        //console.log(self.copyColumnListVisibility);
                        //console.log(visibilityOrder);

                        //self.copyColumnListVisibility = [];
                        //for (var cc in visibilityOrder) {
                        //    var obj2 = jQuery.extend(true, {}, visibilityOrder[cc]);
                        //    self.copyColumnListVisibility.push(obj2);
                        //};
                    }
                })
            };
        },
        changeColumnVisibility: function () {
            this.settingsSaveAvailable = false;
        },
        changecolumnwidth: function () {
            //console.log('changecolumnwidth');
            return;

            const tableHeader = document.querySelectorAll('.el-table__header-wrapper .el-table__header tr');
            var widthColumns = {};

            if (tableHeader[0] === undefined) { return; };

            for (var h = 0; h < tableHeader[0].children.length; h++) {
                var item = tableHeader[0].children[h];
                var itemWidth = $(item).width();
                //console.log('itemWidth', $(item).attr('data-width'), $(item).data().prop);
                if (itemWidth < 0 || itemWidth === undefined) itemWidth = $(item).attr('data-width'); //ep default
                $(item).attr('data-width', itemWidth);
                widthColumns[$(item).data().prop] = itemWidth || 80;
            };

            this.widthColumns = widthColumns;
            //console.log('change width', widthColumns);

            //this.settingsSaveAvailable = false;

            localStorage.setItem(this.pageName + '_ColumnWidth', JSON.stringify(widthColumns));
        },
        saveColumnVisibility: function (getFromCopy) {
            localStorage.removeItem(this.pageName + '_ColumnVisibility');
            if (getFromCopy === 1) {
                localStorage.setItem(this.pageName + '_ColumnVisibility', JSON.stringify(this.copyColumnListVisibility));
            }
            else {
                localStorage.setItem(this.pageName + '_ColumnVisibility', JSON.stringify(this.columnListVisibility));
            };
            this.settingsSaveAvailable = false;
        },
        saveColumnOrder: function () {
            localStorage.removeItem(this.pageName + '_ColumnOrder');
            localStorage.setItem(this.pageName + '_ColumnOrder', JSON.stringify(this.orderColumns));
        },

        //read configurations
        getColumnVisibility: function (column) {
            var columnVisibilityFiltered = this.columnListVisibility.filter(obj => { return obj.column === column; });
            if (columnVisibilityFiltered.length > 0) {
                return columnVisibilityFiltered[0].value;
            }
            else {
                var columnVisibilityFilteredDisplayName = this.columnListVisibility.filter(obj => { return obj.name === column; });
                if (columnVisibilityFilteredDisplayName.length > 0) {
                    return columnVisibilityFilteredDisplayName[0].value;
                }
                else {
                    //console.error('Brak definicji widoczności kolumny: ' + column);
                };
            }
        },
        getColumnVisibilityClass: function (column) {
            var columnVisibilityFiltered = this.columnListVisibility.filter(obj => { return obj.column === column; });
            if (columnVisibilityFiltered.length > 0) {
                return columnVisibilityFiltered[0].value === true ? '' : 'hide1';
            }
            else {
                var columnVisibilityFilteredDisplayName = this.columnListVisibility.filter(obj => { return obj.name === column; });
                if (columnVisibilityFilteredDisplayName.length > 0) {
                    return columnVisibilityFilteredDisplayName[0].value === true ? '' : 'hide1';
                }
                else {
                    //console.error('Brak definicji widoczności kolumny: ' + column);
                };
            }
        },
        getColumnwidth: function (setToo) {
            //console.log('getColumnwidth', setToo);
            const tableHeader = document.querySelectorAll('.el-table__header-wrapper .el-table__header tr');
            var widthColumns = {};

            if (tableHeader[0] === undefined) { return; };

            for (var h = 0; h < tableHeader[0].children.length; h++) {
                var item = tableHeader[0].children[h];

                var itemWidth = $(item).width();
                //console.log('itemWidth', $(item).attr('data-width'), $(item).data().prop);
                if (itemWidth < 0 || itemWidth === undefined) itemWidth = $(item).attr('data-width'); //ep default

                if (setToo) {
                    $(item).attr('data-width', itemWidth);
                    //this.settingsSaveAvailable = false;
                };
                widthColumns[$(item).data().prop] = itemWidth;
            };

            this.widthColumns = widthColumns;
            //console.log('widthColumns', widthColumns);
            //localStorage.setItem(this.pageName + '_ColumnWidth', JSON.stringify(widthColumns));
        },
        getColumnOrder(setToo) {
            //console.log('getColumnOrder', setToo);
            const tableHeader = document.querySelectorAll('.el-table__header-wrapper .el-table__header tr');
            var orderColumns = {};

            if (tableHeader[0] === undefined) { return; };

            for (var h = 0; h < tableHeader[0].children.length; h++) {
                var item2 = tableHeader[0].children[h];
                if (!$(item2).hasClass('gutter')) {
                    if (setToo) {
                        $(item2).attr('data-indexorder', h);
                        //this.settingsSaveAvailable = false;
                    };
                    orderColumns[$(item2).data().prop] = h;
                };
            };

            this.orderColumns = orderColumns;
            //console.log('orderColumns', orderColumns);
            //localStorage.setItem(this.pageName + '_ColumnOrder', JSON.stringify(orderColumns));
        },
        headerChangeLayout: function () {
            this.getColumnwidth(true);
            this.getColumnOrder(true);

            //this.$root.$refs.agreementTable.setColumnOrdering(1);
        },

        //helpers - todo:ep czy można przenieść tę metodę do Helpers?
        searchDeep: function (hay, needle, accumulator) {
            //wyszukiwanie wartości NEEDLE w obiekcie piętrowym HAY

            let self = this;
            var accumulator2 = accumulator || [];
            if (typeof hay == 'object') {
                for (var i in hay) {
                    self.searchDeep(hay[i], needle, accumulator2) == true ? accumulator2.push(hay) : 1;
                }
            }

            return new RegExp(needle).test(hay) || accumulator2;
        },
        //mock ##############################

        handleSelectionChangeMock: function (rows) {
            this.selectedRows = rows;
            this.listOperations = [];

            for (var r in rows) {
                for (var o in rows[r].Operations) {
                    var operation = rows[r].Operations[o];
                    if (!(this.listOperations.find((o) => { return o.Symbol == operation.Symbol })))
                        this.listOperations.push(operation);
                }
            };

            this.isSelectedAnyRow = rows.length > 0;
        },
        handleSizeChangeMock: function (pageSize) {
            this.pageSize = pageSize;
            this.refresh();
        },
        handleCurrentChangeMock: function (currentPage) {
            this.currentPage = currentPage;
            this.refresh();
        },

        // /mock ##############################
    },
    watch: {
        searchModel: {
            handler(newValue, oldValue) {
                //console.log('searchModel', val, oldVal);

                //var changed = val.filter(function (p, idx) {
                //    return Object.keys(p).some(function (prop) {
                //        return p[prop] !== oldVal[idx][prop];
                //    })
                //});

                this.getSearchFormValues();
            },
            deep: true
        },
        showFilters: function (val) {
            //todo:ep
            //this.setTableHeight(null, null, null);
            //this.setTableHeightCmp(null, null, null);
        },
    },
    created() {
        window.addEventListener("resize", this.setTableHeight);

        var isUserSettingsLoaded = localStorage.getItem('IsUserSettingsLoaded');
        this.isUserSettingsLoaded = isUserSettingsLoaded;
    },
    mounted() {
        $('.d_searchModelInfo').fadeIn(500);
    },
    updated() {
        //sortowanie kolumn na panelu bocznym
        if (Helpers.isNullOrEmpty(this.orderColumns)) {
            this.getColumnOrder();
            var self = this;
            self.copyColumnListVisibility.sort(function (a, b) {
                var aName = self.orderColumns[a.column];
                var bName = self.orderColumns[b.column];
                return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
            });
        };

        //draggable na panelu bocznym
        this.makeSortableSettings();

        //pierwsze ładowanie ustawień, przed renderowaniem strony jeszcze nie jest uzupełniony localstorage więc musimy
        //zaktualizować dane ustawień jeśli wiemy, że już się uzupełnił
        var isUserSettingsLoaded = localStorage.getItem('IsUserSettingsLoaded');
        if (this.isUserSettingsLoaded !== isUserSettingsLoaded) {
            this.isUserSettingsLoaded = isUserSettingsLoaded;
            //console.log('załadowano ustawienia użytkownika, odświeżenie parametrów grida');

            this.orderColumns = JSON.parse(localStorage.getItem(this.pageName + '_ColumnOrder')) || {};
            this.widthColumns = JSON.parse(localStorage.getItem(this.pageName + '_ColumnWidth')) || {};
            this.reduceOperationColumn = localStorage.getItem(this.pageName + '_ReduceOperationColumn') === "true" || false;

            var columnVisibility = JSON.parse(localStorage.getItem(this.pageName + '_ColumnVisibility'));
            if (columnVisibility !== null && !Helpers.isNullOrEmpty(columnVisibility)) {
                this.columnListVisibility = columnVisibility;
            };
        };
    },
    destroyed() {
        window.removeEventListener("resize", this.setTableHeight);
    },
}