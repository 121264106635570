import $ from 'jquery'

export const Helpers = {
    /**
     * Check if object is null or empty 
     * @param {any} value - object
     * @param {any} zeroIsBad - bool, default if value == 0 -> return true
     */
    isNullOrEmpty(value, zeroIsBad = true) {
        //uwaga na $.isEmptyObject(value) - przy datach zwraca błędnie true

        if (typeof (value) == "object") {
            if (value === null) //null
                return true;

            if (value.length > 0) //table
                return false;

            if (value instanceof Date) //date
                return false;

            for (var i in value) {
                return false; //object with props
            };

            return true;
        };

        return (
            (zeroIsBad && value === 0) ||
            (zeroIsBad && value === "0") ||
            value === "" ||
            value === null ||
            value == undefined ||
            (typeof (value) == "object" && value === {}));
    },
    /**
     * Check if pressed key is number key or operationKey 
     * @param event - object
     */
    isIntNumber(event) {
        if (!/\d/.test(event.key) && !this.isOperationKey(event))
            return event.preventDefault();
    },
    /**
     * Check if pressed key is number key or operationKey or dot or comma 
     * @param event - object
     * @param isMinusOK - if minus is acceptable in field
     * @param value - value field for check if minus exists yet
     */
    isDecimalNumber(event, isMinusOK = false, value = 0) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        //console.log('event.keyCode', event.keyCode, keyCode);

        var acceptableChars = [];
        acceptableChars.push(46, 110, 190); //kropka
        acceptableChars.push(44, 188); //przecinek
        acceptableChars.push(48, 49, 50, 51, 52, 53, 54, 55, 56, 57); //top
        acceptableChars.push(96, 97, 98, 99, 100, 101, 102, 103, 104, 105); //num

        if (isMinusOK) {
            if (this.isNullOrEmpty(value, false)) {
                acceptableChars.push(189, 109); //minus
            };
        };

        if (!acceptableChars.includes(keyCode) && !this.isOperationKey(event)) {
            event.preventDefault();
            return false;
        };

        return true;
    },
    /**
     * Check if pressed key is operation key
     * @param event - object
     */
    isOperationKey(event) {
        if (event.ctrlKey) {
            switch (event.keyCode) {
                case 65: //a (select all)
                case 67: //c (copy)
                case 86: //v (paste)
                case 88: //x (cut)
                    return true;
                default:
                    return false;
            }
        };

        switch (event.keyCode) {
            case 8: //backspace
            case 46: //delete
            case 37: //arrow left
            case 39: //arrow right
            case 9: //tab
                return true;
            default:
                return false;
        };
    },
    /**
    * Get :root css property value with checking nullEmpty
    * @param property - string
    * @param propertyType - string, "int", "float", default "string"
    * @param removeSuffix - string, ex. 'px', if given in result will be removed
    */
    getRootCssValue(property, propertyType, removeSuffix) {
        var propertyValue = {
            RawValue: getComputedStyle(document.documentElement).getPropertyValue(property),
            ReplacedValue: null,
            ParsedValue: null
        };

        if (!Helpers.isNullOrEmpty(propertyValue.RawValue)) {
            propertyValue.ReplacedValue = propertyValue.RawValue.replace(removeSuffix, '');

            if (propertyType == "int") {
                propertyValue.ParsedValue = parseInt(propertyValue.ReplacedValue);
            }
            else if (propertyType == "float") {
                propertyValue.ParsedValue = parseFloat(propertyValue.ReplacedValue);
            };
        };

        return propertyValue;
    },
}