<template>
  <div class="login-footer">
    <div class="login-footer-left">
      &copy; SAPICO {{ new Date().getFullYear() }}
    </div>
    <div class="login-footer-middle">
      ENEA S.A.
    </div>
    <div class="login-footer-right">
      <a target="_blank" href="https://enea.pl/trade24">{{ $t('login.seeMore') }}</a>
    </div>
    <div class="login-footer-right-max">
      <a target="_blank" href="/files/Dane_osobowe_i_cookies.pdf">{{
        $t('login.cookiesInfo') }}</a>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'PasswordPageFooter',
  setup() {
    return {};
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  created() {

  },
  computed: {

  },
  methods: {

  },
  watch: {

  }
}
</script>
