<template>
    <div class="tge-chart-canvas" :style="['height: auto; min-height:300px; max-height:' + this.maxHeight ?? 500 + 'px; width: 100%; background-color: #fff;']">
        <!-- <el-slider v-model="zoomRangeValue" range :min="1" :max="labels.length > 0 ? labels.length : 1"
            @input="changeSliderIndex();" :format-tooltip="formatSliderTooltip" style="padding:10px;width:95%;height:8px;" /> -->
        <canvas id="tgeChartIndex"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { Renderers } from '@/scripts/renderers'
import { getRelativePosition } from 'chart.js/helpers';
import zoomPlugin from 'chartjs-plugin-zoom';
import { t } from '@/setup/i18n-setup'

export default {
    name: 'ChartTGEIndex',
    props: ['msg', 'labels', 'datasets', 'colorMinRate', 'colorMaxRate', 'colorDKR', 'colorVolume', 'mediumTypeRadio', 'aspectRatio', 'maxHeight'],
    setup() {
        return { Renderers };
    },
    data() {
        this.tgeChartIndex = null; //uniknięcie maximum call stack exceeded
        return {
            //tgeChartIndex: null, //maximum call stack exceeded
            zoomRangeValue: [1, this.labels.length > 0 ? this.labels.length : 1],
            xMin: null,
            xMax: null,
            yMin: null,
            yMax: null,

            chartData: [],
            chartLabels: []
        };
    },
    computed: {
        chartConfig() {
            let self = this;

            return {
                type: 'line',
                data: {
                    labels: this.chartLabels,
                    datasets: this.chartData
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: this.aspectRatio ?? 2, //(width/height)
                    legend: {
                        position: 'right'
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    parsing: {
                        xAxisKey: 'x',
                        yAxisKey: 'y'
                    },
                    layout: {
                        padding: {
                            top: 10,
                            left: 10,
                            right: 10,
                            bottom: 10
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                minRotation: 30,
                                maxRotation: 30,
                            }
                        },
                        y: {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            title: {
                                display: true,
                                text: t('chart.RateIndex')
                            },
                            ticks: {
                                callback: function (label, index, labels) {
                                    return Renderers.amountFormat(label);
                                }
                            }
                        }
                    },
                    onHover: (e) => {
                        self.drawLineWithLabels(e, this.tgeChartIndex.canvas.getContext('2d'));
                    },
                    plugins: {
                        tooltip: {
                            enabled: true,
                            displayColors: false, //aby nie było kwadratu z kolorem
                            callbacks: {
                                beforeTitle: function (context) {
                                    var dataItem = context[0].dataIndex;
                                    return t('chart.Date') + ": " + context[0].label
                                        + "\n" + self.chartData[0].label + ": " + Renderers.amountFormat(self.chartData[0].data[dataItem].y);
                                },
                                title: function (context) {
                                    return '';
                                },
                                label: function (context) {
                                    return;
                                }
                            }
                        },
                        datalabels: {
                            color: '#888',
                            formatter: (value, context) => value.value > 0 ? value.value : ''
                        },
                        legend: {
                            position: "top"
                        },
                        // zoom: {
                        //     zoom: {
                        //         wheel: {
                        //             enabled: true,
                        //         },
                        //         pinch: {
                        //             enabled: true
                        //         },
                        //         mode: 'xy',
                        //     }
                        // }
                    },
                    animation: {

                    }
                },
                plugins: [
                    {
                        id: 'custom_canvas_background_color',       //do rysowania tła
                        beforeDraw: (chart) => {
                            const ctx = chart.canvas.getContext('2d');
                            ctx.save();
                            ctx.globalCompositeOperation = 'destination-over';
                            ctx.fillStyle = 'white';
                            ctx.fillRect(0, 0, chart.width, chart.height);
                            ctx.restore();
                        }
                    },
                ]
            }
        },
    },
    mounted() {
        Chart.register(zoomPlugin);

        this.chartData = JSON.parse(JSON.stringify(this.datasets));
        this.chartLabels = JSON.parse(JSON.stringify(this.labels));

        this.loadChart();
    },
    created() {

        if (this.colorMinRate) {
            this.datasets[1].backgroundColor = this.colorMinRate; //MinRate
            this.datasets[1].borderColor = this.colorMinRate; //MinRate
        };

    },
    methods: {
        updateChart() {
            this.tgeChartIndex.update();
        },
        loadChart() {
            document.addEventListener('mousemove', this.onMouseMove, false);
            const ctx = document.getElementById("tgeChartIndex");
            if (this.tgeChartIndex) {
                this.tgeChartIndex.destroy();
            }
            this.tgeChartIndex = new Chart(ctx, this.chartConfig);
        },
        changeSliderIndex() {
            var start = this.zoomRangeValue[0] - 1;
            var end = this.zoomRangeValue[1];

            this.chartData = JSON.parse(JSON.stringify(this.datasets));
            this.chartLabels = JSON.parse(JSON.stringify(this.labels));

            var cat0 = this.chartData[0].data.slice(start, end);

            this.chartLabels = [];
            this.chartData[0].data = cat0;

            this.loadChart();
        },
        formatSliderTooltip(val) {
            return this.labels[val - 1];
        },

        //chart
        onMouseMove(e) {
            let self = this;
            let mouseX = e.pageX;
            let mouseY = e.pageY;
            if (mouseX < self.xMin || mouseX > self.xMax || mouseY < self.yMin || mouseY > self.yMax) {
                self.tgeChartIndex.clear();
                self.tgeChartIndex.draw();
            }
        },
        changeView(viewName) {
            let self = this;
            if (viewName == "dataset1") {
                self.tgeChartIndex.data = { datasets: datasets1D, labels: labelsDatasets1D };
                currentDataset = datasets1D;
            }
            else {
                self.tgeChartIndex.data = { datasets: datasets2M, labels: labelsDatasets2M };
                currentDataset = datasets2M;
            }
            self.tgeChartIndex.update();
        },
        drawLineWithLabels(e, ctx) {
            let self = this;

            self.tgeChartIndex.clear();
            self.tgeChartIndex.draw();

            const canvasPosition = getRelativePosition(e, self.tgeChartIndex);

            const dataX = self.tgeChartIndex.scales.x.getValueForPixel(canvasPosition.x);
            const dataY = self.tgeChartIndex.scales.y.getValueForPixel(canvasPosition.y);
            self.xMin = self.tgeChartIndex.chartArea.left;
            self.xMax = self.tgeChartIndex.chartArea.right;
            self.yMin = self.tgeChartIndex.chartArea.top;
            self.yMax = self.tgeChartIndex.chartArea.bottom;

            if (canvasPosition.x > self.xMin && canvasPosition.x < self.xMax && canvasPosition.y > self.yMin && canvasPosition.y < self.yMax) {
                ctx.strokeStyle = "red";
                ctx.beginPath();
                ctx.moveTo(self.xMin, canvasPosition.y);
                ctx.lineTo(self.xMax, canvasPosition.y);
                ctx.stroke();
                ctx.textAlign = "center";
                ctx.textBaseline = 'middle';

                ctx.fillStyle = "red";
                let sideLabelSize = 26;
                ctx.fillRect(self.xMax + 5, canvasPosition.y - (sideLabelSize / 2), sideLabelSize * 1.7, sideLabelSize);
                ctx.fillRect(self.xMin - 5 - sideLabelSize * 2, canvasPosition.y - (sideLabelSize / 2), sideLabelSize * 2, sideLabelSize);

                //dziubki
                //prawy
                ctx.beginPath();
                ctx.moveTo(self.xMax, canvasPosition.y);
                ctx.lineTo(self.xMax + 5, canvasPosition.y - 5);
                ctx.lineTo(self.xMax + 5, canvasPosition.y + 5);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();

                //lewy
                ctx.beginPath();
                ctx.moveTo(self.xMin, canvasPosition.y);
                ctx.lineTo(self.xMin - 5, canvasPosition.y - 5);
                ctx.lineTo(self.xMin - 5, canvasPosition.y + 5);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();

                ctx.fillStyle = "white";
                ctx.fillText(Renderers.amountFormat(Math.round(dataY, 0)), self.xMin - 5 - sideLabelSize, canvasPosition.y);
            }
        },
    },
    watch: {

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
