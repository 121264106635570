import axios from 'axios';
import * as base from '@/api/BaseApi';

/**
 * Login user using credentials
 * @param {any} username
 * @param {any} password
 * @param {any} success
 * @param {any} fail
 */
export const login = (username, password, success, fail) => {

    var params = "userName=" + encodeURIComponent(username) +
        "&password=" + encodeURIComponent(password) +
        "&grant_type=password";

    axios
        .post("/api/token",
            params,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
        )
        .then((response) => success(response))
        .catch((response) => fail(response));
};

/**
 * Login user using refresh token
 * @param {any} refreshToken
 * @param {any} login
 * @param {any} success
 * @param {any} fail
 */
export const refreshToken = (refreshToken, login, success, fail) => {
    return axios
        .post("/api/token",
            "refresh_token=" + refreshToken +
            "&grant_type=refresh_token" +
            "&user_login=" + login,
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
        .then((response) => success(response))
        .catch((response) => fail(response));
};

/**
 * Send notification about forgotten password
 * @param {any} model
 * @param {any} success
 * @param {any} fail
 */
export const forgotPassword = (model, success, fail) => {
    return base.runOperation("/api/customeruser/OpCustomerUserForgotPassword", 0, model, success, fail);
};

/**
 * Send SMS code to user
 * @param {any} model
 * @param {any} success
 * @param {any} fail
 */
export const sendSMSCode = (model, success, fail) => {
    return base.runOperation("/api/customeruser/OpCustomerUserSendResetSMS", 0, model, success, fail);
};

/**
 * Reset user password
 * @param {any} model
 * @param {any} success
 * @param {any} fail
 */
export const resetPassword = (model, success, fail) => {
    return base.runOperation("/api/customeruser/OpCustomerUserResetPassword", 0, model, success, fail);
}

/**
 * Change password
 * @param {any} model
 * @param {any} success
 * @param {any} fail
 */
export const changePassword = (model, success, fail) => {
    return base.runOperation("/api/customeruser/OpCustomerUserChangePassword", 0, model, success, fail);
}

/**
 * Get permissions from service
 * @param {any} success
 * @param {any} fail
 */
export const getPermissions = (success, fail) => {
    return axios
        .get("/api/account/getPermissions")
        .then((response) => success(response))
        .catch((response) => fail(response));
};

/**
 * Get user language
 * @param {*} success 
 * @param {*} fail 
 * @returns 
 */
export const getLanguage = (success, fail) => {
    return axios
        .get("/api/account/getLanguage")
        .then((response) => success(response))
        .catch((response) => fail(response));
}

/**
 * Change user language
 * @param {*} success 
 * @param {*} fail 
 * @returns 
 */
export const changeLanguage = (success, fail) => {
    return axios.post("/api/account/changeLanguage")
        .then((response) => success(response))
        .catch((response) => fail(response));
}

/**
 * Get permissions from service
 * @param {any} success
 * @param {any} fail
 */
export const finishSession = async(success, fail) => {
    return axios
        .get("/api/account/logout")
        .then((response) => success(response))
        .catch((response) => fail(response));
};

