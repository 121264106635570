<template>
  <h2>
    {{ $t('headers.QuotesTGE') }}
    <el-radio-group v-model="quotesMediumTypeRadio" class="pull-right" @change="onMediumTypeRadioChange();">
      <el-radio label="EE" v-if="this.parent.selectedContractMedium?.indexOf('EE') > -1">{{ $t('headers.energyRadio') }}
      </el-radio>
      <el-radio label="GAS" v-if="this.parent.selectedContractMedium?.indexOf('GAS') > -1">{{ $t('headers.gasRadio') }}
      </el-radio>
      <el-radio label="PR" v-if="this.parent.selectedContractMedium?.indexOf('PR') > -1">{{ $t('headers.pmRadio') }}
      </el-radio>
    </el-radio-group>
  </h2>

  <div class="sp-client-form-panel" style="padding:10px;display:flow-root;width: 100%;"
    v-loading="parent.tgeQuotesLoading">

    <div id="quotesTable" style="margin-bottom: 20px;height:270px;display:inline;" v-if="showQuotations">
      <Transition>
        <QuotationDashboardListCmp ref="quotationListComponent" v-if="!Helpers.isNullOrEmpty(quotationListProps)"
          inline-template :parent="quotationListProps.parent" :listConfiguration="quotationListProps.listConfiguration"
          :quotationsList="quotationListProps.quotationsList">
        </QuotationDashboardListCmp>
      </Transition>
    </div>

    <Transition>
      <div style="height:450px;" v-if="showQuotations && !showIndexChart"
        v-loading="Helpers.isNullOrEmpty(chartTGEProps)">
        <ChartTGE v-if="!Helpers.isNullOrEmpty(chartTGEProps)" :labels="chartTGEProps.labels"
          :datasets="chartTGEProps.datasets" :mediumTypeRadio="quotesMediumTypeRadio" :aspectRatio="aspectRatio" />
        <div style="background-color: #fff;padding-bottom:10px;">
          <el-radio-group v-model="chartTGEPeriod" size="small" class="pull-left" @change="onChartTGEPeriodChange()">
            <el-radio-button :label="chartPeriods.TwoWeeks">{{ chartPeriods.TwoWeeks }}</el-radio-button>
            <el-radio-button :label="chartPeriods.OneMonth">{{ chartPeriods.OneMonth }}</el-radio-button>
            <el-radio-button :label="chartPeriods.ThreeMonths">{{ chartPeriods.ThreeMonths }}</el-radio-button>
            <el-radio-button :label="chartPeriods.SixMonths">{{ chartPeriods.SixMonths }}</el-radio-button>
            <el-radio-button :label="chartPeriods.Max">{{ chartPeriods.Max }}</el-radio-button>
          </el-radio-group>
          <el-popover placement="left" :title="$t('messages.ChooseDateRange')" :width="370" trigger="click">
            <template #reference>
              <el-button :type="dateRangeCalendarButtonType" size="small" class="pull-right mar-r-8">
                <span class="mdi mdi-calendar"></span>
              </el-button>
            </template>
            <el-date-picker v-model="searchModelForProduct.QuoteDateRange" type="daterange" value-format="YYYY-MM-DD"
              unlink-panels :range-separator="$t('datePicker.to')" :start-placeholder="$t('datePicker.start')"
              :end-placeholder="$t('datePicker.end')" :shortcuts="datePickerRangeShortcuts"
              @change="onChangeChartTGEDateRange()" />
          </el-popover>
          <div id="selectedDateRange" class="pull-right mar-r-8"></div>
        </div>
      </div>
    </Transition>

    <div id="indexTable" style="margin-bottom: 20px;height:270px;display:inline;" v-if="!showQuotations">
      <Transition>
        <TradingIndexDashboardListCmp ref="tradingIndexListComponent" v-if="!Helpers.isNullOrEmpty(indexListProps)"
          inline-template :parent="indexListProps.parent" :listConfiguration="indexListProps.listConfiguration"
          :indexesList="indexListProps.indexesList">
        </TradingIndexDashboardListCmp>
      </Transition>
    </div>

    <Transition>
      <div style="height:450px;" v-if="!showQuotations || showIndexChart"
        v-loading="Helpers.isNullOrEmpty(chartTGEIndexProps)">
        <ChartTGEIndex v-if="!Helpers.isNullOrEmpty(chartTGEIndexProps)" :labels="chartTGEIndexProps.labels"
          :datasets="chartTGEIndexProps.datasets" :aspectRatio="aspectRatio" />
        <div v-if="!Helpers.isNullOrEmpty(chartTGEIndexProps)" style="background-color: #fff;padding-bottom:10px;">
          <el-radio-group v-model="chartTGEIndexPeriod" size="small" class="pull-left"
            @change="onChartTGEIndexPeriodChange()">
            <el-radio-button :label="chartPeriods.TwoWeeks">{{ chartPeriods.TwoWeeks }}
            </el-radio-button>
            <el-radio-button :label="chartPeriods.OneMonth">{{ chartPeriods.OneMonth }}
            </el-radio-button>
            <el-radio-button :label="chartPeriods.ThreeMonths">{{ chartPeriods.ThreeMonths }}
            </el-radio-button>
            <el-radio-button :label="chartPeriods.SixMonths">{{ chartPeriods.SixMonths }}
            </el-radio-button>
            <el-radio-button :label="chartPeriods.Max">{{ chartPeriods.Max }}
            </el-radio-button>
          </el-radio-group>
          <el-popover placement="left" :title="$t('messages.ChooseDateRange')" :width="370" trigger="click">
            <template #reference>
              <el-button :type="dateRangeCalendarButtonType" size="small" class="pull-right mar-r-8">
                <span class="mdi mdi-calendar"></span>
              </el-button>
            </template>
            <el-date-picker v-model="searchModelForIndex.IndexDateRange" type="daterange" value-format="YYYY-MM-DD"
              unlink-panels :range-separator="$t('datePicker.to')" :start-placeholder="$t('datePicker.start')"
              :end-placeholder="$t('datePicker.end')" :shortcuts="datePickerRangeShortcuts"
              @change="onChangeChartTGEIndexDateRange()" />
          </el-popover>
          <div id="selectedDateRangeForTGEIndex" class="pull-right mar-r-8"></div>
        </div>
      </div>
    </Transition>

  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<script>
import $ from 'jquery'
import moment from 'moment'
import { chartPeriods } from '@/scripts/const'
import { Helpers } from '@/scripts/helpers'
import { Renderers } from '@/scripts/renderers'
import { Notifications } from '@/scripts/notifications'
import { Configurations } from '@/scripts/configuration'
import { Logger } from '@/scripts/logger'
import * as quotationApi from '@/api/QuotationApi'
import QuotationDashboardListCmp from '@/components/Quotation/QuotationDashboardListCmp'
import ChartTGE from '@/components/Chart/ChartTGE.vue';
import { ChartTGEJs } from '@/components/Chart/chartTGE.js'
import * as indexApi from '@/api/TradingIndexApi'
import TradingIndexDashboardListCmp from '@/components/TradingIndex/TradingIndexDashboardListCmp'
import ChartTGEIndex from '@/components/Chart/ChartTGEIndex.vue';
import { ChartTGEIndexJs } from '@/components/Chart/chartTGEIndex.js'

export default {
  name: 'DashboardTGECmp',
  props: ['parent', 'mediumTypeRadioTGE'],
  mixins: [Configurations, ChartTGEJs, ChartTGEIndexJs],
  setup() {
    return { Helpers, Renderers, chartPeriods };
  },
  data() {
    return {
      //components settings
      refresh: false,
      mediumTypeRadio: null,
      showQuotations: true,
      showIndexChart: false,
      quotesMediumTypeRadio: null,

      //quotation
      quotationListProps: {},
      searchModel: {},
      quotationsList: [],
      selectedProduct: null, //zaznaczony rekord na liście notowań z pogrupowanymi produktami
      loadingQuotationTable: false,

      //trading index
      searchModelIndex: {},
      indexListProps: {},
      indexesList: [],
      loadingIndexTable: false,

      //overrides
      // chartTGEPeriod: this.chartPeriods.TwoWeeks,
      // chartTGEIndexPeriod: this.chartPeriods.TwoWeeks,
    };
  },
  mounted() {
    //from props
    this.setFromProps();
  },
  created() {

  },
  computed: {
    aspectRatio: function () {
      //gt1920, gt1200, gt992, gt768, gt500, lt500, elseValue
      return Renderers.numberSize(2, 1.6, 1.6, 1.6, 1.1, 0.8, 1.6);
    },
  },
  methods: {
    //set properties
    setFromProps: function () {
      this.quotesMediumTypeRadio = this.$props.mediumTypeRadioTGE;

      this.mediumTypeRadio = this.quotesMediumTypeRadio;
      this.searchModel.MediumType = this.mediumTypeRadio;
      this.searchModelIndex.IndexType = this.mediumTypeRadio == 'PR' ? 'TGEozea' : null;
      this.searchModelForProduct.MediumType = this.mediumTypeRadio;
    },
    setQuotationListProps: function () {
      let self = this;

      self.quotationListProps = {
        listConfiguration: {
          //from configuration.js
          showSearch: false,
          showSearchPanel: false,
          showExpandColumn: false,
          showSelectionColumn: false,
          showQuickSearch: false,
          showBreadcrumb: false,
          showBaseSearchPanel: false,

          columnListVisibility: [
            { column: 'ChangeDate', value: false },
            { column: 'CreateDate', value: false },

            { column: 'DailySettlementPrice', value: true },
            { column: 'FirstTradePrice', value: false },
            { column: 'Id', value: false },
            { column: 'Lots', value: true },
            { column: 'MaximumPrice', value: true },
            { column: 'MinimumPrice', value: true },
            { column: 'NumberTransactions', value: false },
            { column: 'ProductSymbol', value: true },

            { column: 'TotalValue', value: false },
            { column: 'TotalVolume', value: false },
            { column: 'TradingSessionId', value: false },
            { column: 'QuoteDate', value: true },
            { column: 'MediumType', value: true },

            { column: 'expand', value: false },
            { column: 'selection', value: false }
          ],
        },
        givenSearchModel: {},
        parent: self,
        quotationsList: self.quotationsList
      };
    },
    setIndexListProps: function () {
      let self = this;

      self.indexListProps = {
        listConfiguration: {
          //from configuration.js
          showSearch: false,
          showSearchPanel: false,
          showExpandColumn: false,
          showSelectionColumn: false,
          showQuickSearch: false,
          showBreadcrumb: false,
          showBaseSearchPanel: false,

          columnListVisibility: [
            { column: 'ChangeDate', value: false },
            { column: 'CreateDate', value: false },

            { column: 'Id', value: false },
            { column: 'IndexType', value: true },
            { column: 'IndexDate', value: true },
            { column: 'Price', value: true },

            { column: 'TradingSessionId', value: true },

            { column: 'Volume', value: true },
            { column: 'expand', value: false },
            { column: 'selection', value: false },
          ],
        },
        givenSearchModel: {},
        parent: self,
        indexesList: self.indexesList
      };
    },

    //main fetching
    fetchQuotationListAndSetProps: function () {
      let self = this;

      this.loadingQuotationTable = true;

      this.quotations = [];

      self.quotationListProps = null;

      // additional index TGE24/TGEgasDA
      var additionalIndex = null;
      var searchModelAddIndex = {
        ContractId: this.parent.selectedContractId,
        IndexType: this.mediumTypeRadio == 'EE' ? 'TGE24' : this.mediumTypeRadio == 'GAS' ? 'TGEgasDA' : 'TGEozea'
      };
      indexApi.getLatest({
        searchModel: JSON.stringify($.extend(true, {}, searchModelAddIndex)),
        pageIndex: 1,
        pageSize: 1
      },
        (response) => {
          var isCorrectResult = Notifications.showOperationMessage(response.data, true);
          if (!isCorrectResult) {
            return;
          };

          var additionalIndexes = response.data.Model.data;
          if (additionalIndexes?.length > 0) {
            additionalIndex = additionalIndexes[0];
          };
        },
        (error) => {
          Logger.logException(error);
        }
      );
      // /additional index TGE24/TGEgasDA

      this.searchModel.ContractId = this.parent.selectedContractId;
      quotationApi.getLatestByProduct({
        searchModel: JSON.stringify($.extend(true, {}, this.searchModel)),
        pageIndex: 1,
        pageSize: 999
      },
        (response) => {
          var isCorrectResult = Notifications.showOperationMessage(response.data, true);
          if (!isCorrectResult) {
            this.loadingQuotationTable = false;
            return;
          }

          this.quotationsList = response.data.Model.data;

          //sztuczny rekord z indeksem
          if (additionalIndex != null) {
            this.quotationsList.push({
              IndexType: additionalIndex.IndexType,
              ProductSymbol: additionalIndex.IndexType,
              QuoteDate: additionalIndex.IndexDate,
              DailySettlementPrice: additionalIndex.Price,
              IsIndex: true
            });
          };

          if (this.quotationsList[0]) {
            this.setQuotationListProps();
            if (this.quotationsList.length == 1 && this.quotationsList[0].IsIndex == true) {
              //wykres dla indeksów
              this.fetchChartTGETradingIndex(this.quotationsList[0].ProductSymbol);
              this.showIndexChart = true;
            }
            else {
              //wykres dla notowań
              this.fetchChartTGEQuotationsForProduct(this.quotationsList[0].ProductSymbol);
              this.showIndexChart = false;
            };
          };

          this.loadingQuotationTable = false;
        },
        (error) => {
          Logger.logException(error);

          this.loadingQuotationTable = false;
        }
      );

    },
    fetchIndexListAndSetProps: function () {
      let self = this;

      this.loadingIndexTable = true;

      this.indexes = [];

      self.indexListProps = null;

      this.searchModelIndex.ContractId = this.parent.selectedContractId;

      indexApi.getLatest({
        searchModel: JSON.stringify($.extend(true, {}, this.searchModelIndex)),
        pageIndex: 1,
        pageSize: 1
      },
        (response) => {
          var isCorrectResult = Notifications.showOperationMessage(response.data, true);
          if (!isCorrectResult) {
            this.loadingIndexTable = false;
            return;
          }

          this.indexesList = response.data.Model.data;

          if (this.indexesList[0]) {
            this.setIndexListProps();
            this.fetchChartTGETradingIndex(this.indexesList[0].IndexType);
          };

          this.loadingIndexTable = false;
        },
        (error) => {
          Logger.logException(error);

          this.loadingIndexTable = false;
        }
      );
    },

    //on methods
    onMediumTypeRadioChange: function () {
      this.mediumTypeRadio = this.quotesMediumTypeRadio;
      this.parent.tgequotesMediumTypeRadio = this.quotesMediumTypeRadio;
      this.searchModel.MediumType = this.quotesMediumTypeRadio;
      this.searchModelForProduct.MediumType = this.quotesMediumTypeRadio;

      //show trading indexes
      if (this.quotesMediumTypeRadio == 'PR') { //todo:consts
        this.showQuotations = false;
        this.showIndexChart = false;
        this.searchModelIndex.IndexType = 'TGEozea';
        this.fetchIndexListAndSetProps();
      }
      else {
        this.showQuotations = true;
        this.showIndexChart = false;
        this.searchModelIndex.IndexType = null;
        this.fetchQuotationListAndSetProps();
      };
    },
  },
  watch: {
    refresh: function (value) {
      if (value) {
        if (this.quotesMediumTypeRadio != this.parent?.firstContractMedium) {
          this.quotesMediumTypeRadio = this.parent?.firstContractMedium;
        };
        this.onMediumTypeRadioChange();
        this.refresh = false;
      }
    },
    loadingIndexTable: function (value) {
      this.parent.tgeQuotesLoading = value;
    },
    loadingQuotationTable: function (value) {
      this.parent.tgeQuotesLoading = value;
    },
  },
  components: {
    ChartTGE,
    QuotationDashboardListCmp,
    TradingIndexDashboardListCmp,
    ChartTGEIndex
  }
}
</script>
