﻿import axios from 'axios';
import * as base from '@/api/BaseApi';

export const getPage = (params, success, fail) => {
    return base.getRecords("/api/message/pagecustomer", params, success, fail);
};

export const get = (id, success, fail) => {
    return base.getRecord("/api/message/messagecustomer", id, success, fail);
};

export const add = (model, success, fail) => {
    return base.add("/api/message/add", model, success, fail);
};

export const update = (model, success, fail) => {
    return base.update("/api/message/update", model, success, fail);
};
