﻿import { useClientLogStore } from '../stores/ClientLogStore'
import { Notifications } from './notifications'
import { t } from "../setup/i18n-setup"

var lastError;

export const Logger = {
    /**
     * Send error information to the server 
     * @param {any} errorMessage - error message
     * @param {any} stackTrace - exception stack trace
     * @param {boolean} withoutUserNotification - if true: no user notification
     */
    logError(errorMessage, stackTrace, withoutUserNotification = false) {
        var isDebug = false; //todo:prd na potrzeby developmentu i prototypu ustawiamy na true; na PRD na false
        var isAlert = true; //false - notyfikacja, true - okienko

        if (isDebug) {
            if (lastError === errorMessage)
                return;

            lastError = errorMessage;

            console.error(errorMessage);
            isAlert = false;

            Notifications.showMsg(
                t('messages.Error'),
                null,
                errorMessage,
                isAlert,
                'error',
                null
            );
        };

        let error = {
            Message: errorMessage,
            StackTrace: stackTrace,
            LogType: 'ERROR'
        };
        useClientLogStore().add(error,
            (success) => {
                if (!withoutUserNotification)
                    showErrorCode(success);
            },
            (fail) => {
                if (isDebug) {
                    console.error('fail', fail);
                };

                Notifications.showMsg(
                    t('messages.Error'),
                    null,
                    isDebug ? fail?.message + ' ' + JSON.stringify(fail?.response?.data) : t('error.UnexpectedException'),
                    isAlert,
                    'error',
                    null
                );
            }
        )
    },
    /**
     * Send exception to the server 
     * @param {any} exception
     * @param {boolean} withoutUserNotification - if true: no user notification
     */
    logException(exception, withoutUserNotification = false) {
        if (exception?.response?.status == 401) {
            return;
        };

        if (exception?.response?.status == 403) {
            withoutUserNotification = true; //komunikat dla użytkownika będzie w AccountStore.js w interceptors
        };

        if (exception?.message?.indexOf('this.$t is not a function') > -1) {
            withoutUserNotification = true; //nie pokazuj komunikatu błędu dla $t
        };

        this.logError(exception.message ?? exception, exception.stack, withoutUserNotification);
    }
}

/**
 * Show message with error code
 * @param {any} clientLogResult - ClientLog API result
 */
function showErrorCode(clientLogResult) {
    var uniqueCode = clientLogResult?.data?.Model?.ErrorId;
    Notifications.showMsg(
        t('messages.Error'),
        null,
        uniqueCode ?
            t('error.UnexpectedExceptionWithCode')
            + " " + uniqueCode.toUpperCase()
            : t('error.UnexpectedException'),
        true,
        'error',
        null
    );
}