import $ from 'jquery'
import * as api from '@/api/BaseApi'
import { Notifications } from '@/scripts/notifications'
import { Logger } from '@/scripts/logger'
import { Helpers } from '@/scripts/helpers'
import { fileUploadSettings } from '@/scripts/const'
import { t } from "../setup/i18n-setup"

export const Validators = {
    isNumber: function (rule, value, callback) {
        if (!value)
            callback();

        if (!isNaN(value)) {
            callback();
        } else {
            callback(new Error(t('rules.validNumber')));
        }
    },

    isAmount: function (rule, value, callback) {
        if (!value)
            callback();

        var isValidAmount = /^\d*([\.,])?(\d{0,2})?$/.test(value);
        if (isValidAmount) {
            callback();
        } else {
            callback(new Error(t('rules.validAmount')));
        }
    },

    //todo: regex do ewentualnego rozwinięcia
    isIBAN: function (rule, value, callback) {
        if (!value)
            callback();

        var isIBANNumber = /^[A-Z]{2}[0-9]{26}$/.test(value);
        if (isIBANNumber) {
            callback();
        } else {
            callback(new Error(t('rules.validIBAN')));
        }
    },

    //https://www.modestprogrammer.pl/walidacja-nip-w-javascript
    isNIP: function (rule, value, callback) {
        if (!value)
            callback();

        var isNIPNumber = false;

        if (typeof value !== 'string')
            return false;

        value = value.replace(/[\ \-]/gi, '');

        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(value.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(value.substr(i, 1)) * weight[i]);
        }

        isNIPNumber = sum % 11 === controlNumber;

        if (isNIPNumber) {
            callback();
        } else {
            callback(new Error(t('rules.validNIP')));
        }
    },

    isNullOrEmpty: function (rule, value, callback) {
        if (!Helpers.isNullOrEmpty(value?.trim())) {
            callback();
        } else {
            callback(new Error(t('rules.required')));
        }
    },

    //dla comba
    isNullOrEmptyCombo: function (rule, value, callback) {
        if (!Helpers.isNullOrEmpty(value)) {
            callback();
        } else {
            callback(new Error(t('rules.pickValue')));
        }
    },

    isMobilePhone: function (rule, value, callback) {
        if (!value)
            callback();

        var isValidFirstPattern = /^[0-9]{9}$/.test(value);

        var isValidSecondPattern = /\+[0-9]{10,15}$/.test(value);

        if (isValidFirstPattern || isValidSecondPattern) {
            callback();
        } else {
            callback(new Error(t('rules.validMobilePhone')));
        }
    },
	//czy telefon w formacie +xxyyyyyyyyy lub yyyyyyyyy
    isMobilePhonePL: function (rule, value, callback) {
        if (!value)
            callback();

        var isValidFirstPattern = /^[+][0-9]{11}$/.test(value);

        var isValidSecondPattern = /^[0-9]{9}$/.test(value);
        if (isValidFirstPattern || isValidSecondPattern) {
            callback();
        } else {
            callback(new Error(t('rules.validMobilePhonePL')));
        }
    },

    isPasswordsEqual: function (rule, value, callback, source, options, model) {
        if (!value)
            callback();

        if (model.password === model.confirmPassword) {
            callback();
        } else {
            callback(new Error(t('rules.diffPasswords')));
        }
    },

    isPasswordAndConfirmationEqual: function (rule, value, callback, source, options, password, passwordConfirmation) {
        if (!value)
            callback();
        if (password === passwordConfirmation) {
            callback();
        } else {
            callback(new Error(t('rules.diffPasswords')));
        }
    },

    isTrue: function (rule, value, callback, source, options, val) {
        if (val)
            callback();
        else {
            callback(new Error());
        }
    },

    isCorrectFile: function (rule, value, callback, source, options) {
        if (!value || value.length == 0) {
            callback();
        }
        else {
            var errMsg = '';

            var fileSizeSum = 0;
            for (var f = 0; f < value.length; f++) {
                if (value[f].size > fileUploadSettings.maxFileSize) {
                    errMsg += t('rules.fileTooBig', { fileName: value[f].name }) + ' ';
                };
                fileSizeSum += value[f].size;
            };

            if (fileSizeSum > (fileUploadSettings.maxFilesSizeSum * 1024 * 1024)) {
                errMsg += t('rules.attachmentsTooBig', { max: fileUploadSettings.maxFilesSizeSum }) + ' ';
            };

            var uniqeNames = Array.from(new Set(value?.map((item) => item.name)));
            if (value?.length != uniqeNames?.length) {
                errMsg += t('rules.fileUniqueNames') + ' ';
            };

            for (var fil = 0; fil < value.length; fil++) {
                if (value[fil].name.indexOf('.') < 0) {
                    errMsg += t('rules.fileWithoutExt', { fileName: value[fil].name }) + ' ';
                };
            };

            if (errMsg != '') {
                callback(new Error(errMsg));
            }
            else {
                callback();
            };
        }
    },

    //czy email
    isEmail: function (rule, value, callback) {

        //http://emailregex.com/
        const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;

        let isValid = (
            typeof value === 'string' &&
            value.length <= 320 &&
            !!value.match(emailPattern)
        );

        if (isValid)
            callback();
        else {
            callback(new Error(t('rules.validEmail')));
        }
    },

}