﻿import axios from 'axios';
import * as base from '@/api/BaseApi';

export const getPage = (params, success, fail) => {
    return base.getRecords("/api/contract/page", params, success, fail);
};

export const get = (id, success, fail) => {
    return base.getRecord("/api/contract/contract", id, success, fail);
};

export const add = (model, success, fail) => {
    return base.add("/api/contract/add", model, success, fail);
};

export const update = (model, success, fail) => {
    return base.update("/api/contract/update", model, success, fail);
};

export const exportList = (model, success, fail) => {
    return base.runOperation("/api/contract/OpContractExportList", 0, model, success, fail);
};

export const getCustomerContracts = (contractId, success, fail) => {
    var model = { searchModel: JSON.stringify({ ContractId: contractId }), pageIndex: 1, pageSize: 100 };
    return base.getRecords("/api/customercontract/page", model, success, fail);
};

export const changeName = (id, model, success, fail) => {
    return base.runOperation("/api/contract/OpContractChangeName", id, model, success, fail);
};

export const getContractParameters = (contractId, success, fail) => {
    var model = { searchModel: JSON.stringify({ ContractId: contractId }), pageIndex: 1, pageSize: 100 };
    return base.getRecords("/api/parameter/page", model, success, fail);
};

export const getContractActiveOffers = (contractId, success, fail) => {
    var model = { searchModel: JSON.stringify({ ContractId: contractId, StatusId: 5, ValidOnly: true }), pageIndex: 1, pageSize: 100 };
    return base.getRecords("/api/offer/page", model, success, fail);
};

export const getMenuInformation = (success, fail) => {
    return base.get("/api/contract/getmenuinformation", success, fail);
};

export const getContractMediums = (contractId, success, fail) => {
    var model = { searchModel: JSON.stringify({ ContractId: contractId }), pageIndex: 1, pageSize: 100 };
    return base.getRecords("/api/medium/page", model, success, fail);
};

export const getContractMediumProductTypes = (mediumIds, success, fail) => {
    var model = { searchModel: JSON.stringify({ MediumIds: mediumIds }), pageIndex: 1, pageSize: 100 };
    return base.getRecords("/api/mediumproducttype/page", model, success, fail);
};

export const getContractManager = (contractId, success, fail) => {
    var model = { id: contractId };
    return base.getRecords("/api/contract/getcontractmanager", model, success, fail);
};