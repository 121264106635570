﻿import axios from 'axios';
import * as base from '@/api/BaseApi';

export const getPage = (params, success, fail) => {
    return base.getRecords("/api/tradingindex/page", params, success, fail);
};

export const get = (id, success, fail) => {
    return base.getRecord("/api/tradingindex/tradingindex", id, success, fail);
};

export const add = (model, success, fail) => {
    return base.add("/api/tradingindex/add", model, success, fail);
};

export const update = (model, success, fail) => {
    return base.update("/api/tradingindex/update", model, success, fail);
};

export const getLatest = (params, success, fail) => {
    return base.getRecords("/api/tradingindex/getlatest", params, success, fail);
};

