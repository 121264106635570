import $ from 'jquery'
import { t } from "@/setup/i18n-setup"
import { tgeColors, chartPeriods } from '@/scripts/const'
import moment from 'moment'
import { Notifications } from '@/scripts/notifications'
import { Logger } from '@/scripts/logger'
import * as api from '@/api/TradingIndexApi'

export const ChartTGEIndexJs = {
    data() {
        return {
            chartTGEIndexProps: {},
            chartTGEIndexPeriod: this.chartPeriods.TwoWeeks,
            forIndex: [],
            dateRangeCalendarButtonType: '',
            selectedProduct: null, //zaznaczony rekord na liście notowań z pogrupowanymi produktami
            searchModelForIndex: {},

            TGEIndexFilterDate: null,
        };
    },
    created() {

    },
    methods: {
        fetchChartTGETradingIndex: function (indexType) {
            if (!indexType) {
                this.forIndex = [];
                this.chartTGEIndexProps = null;
                return;
            };

            let self = this;

            this.loading = true;

            this.forIndex = [];

            self.chartTGEIndexProps = null;

            //warunki wyszukiwania
            this.searchModelForIndex.IndexType = indexType;
            this.setChartTGEIndexPeriodDates();

            api.getPage({
                searchModel: JSON.stringify($.extend(true, {}, this.searchModelForIndex)),
                pageIndex: 1,
                pageSize: 999
            },
                (response) => {
                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        this.loading = false;
                        return;
                    }

                    this.forIndex = response.data.Model.data;

                    this.setChartTGEIndexListProps(this.forIndex);

                    this.loading = false;
                },
                (error) => {
                    Logger.logException(error);

                    this.loading = false;
                }
            );
        },
        setChartTGEIndexListProps: function (forIndex) {
            let self = this;

            var labelsChart = [];
            var datasetsChart = [
                {
                    kind: 'Price',
                    label: t('chart.RateIndex'),
                    data: [],
                    borderColor: tgeColors.MinRate,
                    backgroundColor: tgeColors.MinRate,
                    type: 'line',
                    borderWidth: 1,
                    pointBorderWidth: 0,
                    pointStyle: 'rect',
                    order: 0,
                    yAxisID: 'y',
                }
            ];

            for (var i in forIndex) {
                var q = forIndex[i];

                labelsChart.push(q.IndexDate.substring(0, 10));

                var dataMinRate = datasetsChart.filter(f => { return f.kind === 'Price'; })[0];
                dataMinRate.data.push({ x: q.IndexDate.substring(0, 10), y: q.Price, type: "line", kind: t('chart.RateIndex') });

            };

            self.chartTGEIndexProps = {
                labels: labelsChart,
                datasets: datasetsChart,
            };
        },
        setChartTGEIndexPeriodDates: function () {
            if (this.searchModelForIndex.IndexDateRange?.length == 2) {
                this.searchModelForIndex.IndexDateFrom = this.searchModelForIndex.IndexDateRange[0];
                this.searchModelForIndex.IndexDateTo = this.searchModelForIndex.IndexDateRange[1];
                return;
            };

            var referenceDate = this.TGEIndexFilterDate ? moment(new Date(this.TGEIndexFilterDate)) : moment();
            var result = { From: null, To: referenceDate };

            switch (this.chartTGEIndexPeriod) {
                case this.chartPeriods.TwoWeeks:
                    result.From = moment(referenceDate).subtract(2, 'weeks');
                    break;
                case this.chartPeriods.OneMonth:
                    result.From = moment(referenceDate).subtract(1, 'months').startOf('month');
                    break;
                case this.chartPeriods.ThreeMonths:
                    result.From = moment(referenceDate).subtract(3, 'months').startOf('month');
                    break;
                case this.chartPeriods.SixMonths:
                    result.From = moment(referenceDate).subtract(6, 'months').startOf('month');
                    break;
                case this.chartPeriods.Max:
                    result.From = null;
                    break;
                default:
                    result.From = null;
                    result.To = null;
                    break;
            };

            this.searchModelForIndex.IndexDateFrom = result.From;
            this.searchModelForIndex.IndexDateTo = result.To;
        },
        onChangeChartTGEIndexDateRange: function () {
            this.dateRangeCalendarButtonType = 'primary';
            this.chartTGEIndexPeriod = null;

            $('#selectedDateRangeForTGEIndex').html(null);

            if (this.searchModelForIndex.IndexDateRange && this.searchModelForIndex.IndexDateRange[0] && this.searchModelForIndex.IndexDateRange[1]) {
                var from = moment(this.searchModelForIndex.IndexDateRange[0]).format("YYYY-MM-DD");
                var to = moment(this.searchModelForIndex.IndexDateRange[1]).format("YYYY-MM-DD");
                $('#selectedDateRangeForTGEIndex').html(from + " - " + to);
            };

            if (this.selectedProduct)
                this.fetchChartTGETradingIndex(this.selectedProduct.IndexType);
        },
        onChartTGEIndexPeriodChange: function () {
            this.dateRangeCalendarButtonType = '';
            this.searchModelForIndex.IndexDateRange = null;
            $('#selectedDateRangeForTGEIndex').html(null);

            this.setChartTGEIndexPeriodDates();

            if (this.selectedProduct)
                this.fetchChartTGETradingIndex(this.selectedProduct.IndexType);
        },
        onChartTGEIndexFilterDateChange: function (newDate) {
            this.TGEIndexFilterDate = newDate;

            if (this.searchModelForIndex.IndexDateRange
                && this.searchModelForIndex.IndexDateRange[0]
                && this.searchModelForIndex.IndexDateRange[1]) {
                //
            }
            else {
                this.onChartTGEIndexPeriodChange();
            };

        },
    },
};