<template>
  <div class="login-header">
    <table>
      <tr>
        <td class="login-header-logo">
          <el-image :src="require('@/assets/images/Enea.logo.png')" />
        </td>
        <td class="login-header-name">
          <h1>Enea Trade24</h1>
          <h2>{{ $t('headers.NavbarTitle') }}</h2>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'PasswordPageHeader',
  setup() {
    return {};
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  created() {

  },
  computed: {

  },
  methods: {

  },
  watch: {

  }
}
</script>
