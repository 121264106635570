<template>
    <div class="tge-chart-canvas" :style="['height: auto; min-height:300px; max-height:' + this.maxHeight ?? 500 + 'px; width: 100%; background-color: #fff;']">
        <el-slider v-model="zoomRangeValue" range :min="1" :max="labels.length > 0 ? labels.length : 1"
            @input="changeSlider();" :format-tooltip="formatSliderTooltip" style="padding:10px;width:95%;height:8px;" />
        <canvas id="tgeChart"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { Renderers } from '@/scripts/renderers'
import { getRelativePosition } from 'chart.js/helpers';
import zoomPlugin from 'chartjs-plugin-zoom';
import { t } from '@/setup/i18n-setup'

function createDiagonalPattern(color = 'red') {
    // create a 10x10 px canvas for the pattern's base shape
    let shape = document.createElement('canvas')
    shape.width = 10
    shape.height = 10
    // get the context for drawing
    let c = shape.getContext('2d')
    //draw 1st line of the shape
    c.strokeStyle = color
    c.beginPath()
    c.moveTo(2, 0)
    c.lineTo(10, 8)
    c.stroke()
    // draw 2nd line of the shape
    c.beginPath()
    c.moveTo(0, 8)
    c.lineTo(2, 10)
    c.stroke()

    // create the pattern from the shape
    return c.createPattern(shape, 'repeat')
}

export default {
    name: 'ChartTGE',
    props: ['msg', 'labels', 'datasets', 'colorMinRate', 'colorMaxRate', 'colorDKR', 'colorVolume', 'mediumTypeRadio', 'aspectRatio', 'maxHeight'],
    setup() {
        return { Renderers };
    },
    data() {
        this.tgeChart = null; //uniknięcie maximum call stack exceeded
        return {
            //tgeChart: null, //maximum call stack exceeded
            zoomRangeValue: [1, this.labels.length > 0 ? this.labels.length : 1],
            xMin: null,
            xMax: null,
            yMin: null,
            yMax: null,

            chartData: [],
            chartLabels: []
        };
    },
    computed: {
        chartConfig() {
            let self = this;

            //patterny na tle muszą być ustawione tutaj!
            //this.chartData[0].backgroundColor = createDiagonalPattern();

            return {
                type: 'line',
                data: {
                    labels: this.chartLabels,
                    datasets: this.chartData
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: this.aspectRatio ?? 2, //(width/height)
                    legend: {
                        position: 'right'
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    parsing: {
                        xAxisKey: 'x',
                        yAxisKey: 'y'
                    },
                    layout: {
                        padding: {
                            top: 10,
                            left: 10,
                            right: 10,
                            bottom: 10
                        }
                    },
                    scales: {
                        x: {
                            stacked: true,
                            ticks: {
                                minRotation: 30,
                                maxRotation: 30,
                            }
                        },
                        y: {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            title: {
                                display: true,
                                text: t('chart.Rate')
                            },
                            ticks: {
                                callback: function (label, index, labels) {
                                    return Renderers.amountFormat(label);
                                }
                            }
                        },
                        y1: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            title: {
                                display: true,
                                text: t('chart.Lots')
                            },
                            ticks: {
                                callback: function (label, index, labels) {
                                    return Renderers.numberformat(label, 0, 3, ' ', ',');
                                }
                            },

                            // grid line settings
                            grid: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                        },
                    },
                    onHover: (e) => {
                        self.drawLineWithLabels(e, this.tgeChart.canvas.getContext('2d'));
                    },
                    plugins: {
                        tooltip: {
                            enabled: true,
                            displayColors: false, //aby nie było kwadratu z kolorem
                            callbacks: {
                                beforeTitle: function (context) {
                                    var dataItem = context[0].dataIndex;
                                    return t('chart.Date') + ": " + context[0].label
                                        + "\n" + self.chartData[0].label + ": " + Renderers.numberformat(self.chartData[0].data[dataItem].y, 0, ' ', ',')
                                        + "\n" + self.chartData[1].label + ": " + Renderers.amountFormat(self.chartData[1].data[dataItem].y)
                                        + "\n" + self.chartData[2].label + ": " + Renderers.amountFormat(self.chartData[2].data[dataItem].y)
                                        + "\n" + self.chartData[3].label + ": " + Renderers.amountFormat(self.chartData[3].data[dataItem].y)
                                        + "\n" + self.chartData[4].label + ": " + Renderers.amountFormat(self.chartData[4].data[dataItem].y);
                                },
                                title: function (context) {
                                    return '';
                                },
                                label: function (context) {
                                    return;
                                }
                            }
                        },
                        datalabels: {
                            color: '#888',
                            formatter: (value, context) => value.value > 0 ? value.value : ''
                        },
                        legend: {
                            position: "top"
                        },
                        // zoom: {
                        //     zoom: {
                        //         wheel: {
                        //             enabled: true,
                        //         },
                        //         pinch: {
                        //             enabled: true
                        //         },
                        //         mode: 'xy',
                        //     }
                        // }
                    },
                    animation: {

                    }
                },
                plugins: [
                    {
                        id: 'custom_canvas_background_color',       //do rysowania tła
                        beforeDraw: (chart) => {
                            const ctx = chart.canvas.getContext('2d');
                            ctx.save();
                            ctx.globalCompositeOperation = 'destination-over';
                            ctx.fillStyle = 'white';
                            ctx.fillRect(0, 0, chart.width, chart.height);
                            ctx.restore();
                        }
                    },
                ]
            }
        },
    },
    mounted() {
        Chart.register(zoomPlugin);

        this.chartData = JSON.parse(JSON.stringify(this.datasets));
        this.chartLabels = JSON.parse(JSON.stringify(this.labels));

        this.loadChart();
    },
    created() {
        if (this.colorVolume) {
            this.datasets[0].backgroundColor = this.colorVolume; //volume
            this.datasets[0].borderColor = this.colorVolume; //volume
        };

        if (this.colorMinRate) {
            this.datasets[1].backgroundColor = this.colorMinRate; //MinRate
            this.datasets[1].borderColor = this.colorMinRate; //MinRate
        };

        if (this.colorDKR) {
            this.datasets[2].backgroundColor = this.colorDKR; //DKR
            this.datasets[2].borderColor = this.colorDKR; //DKR
        };

        if (this.colorMaxRate) {
            this.datasets[3].backgroundColor = this.colorMaxRate; //MaxRate
            this.datasets[3].borderColor = this.colorMaxRate; //MaxRate
        };
    },
    methods: {
        updateChart() {
            this.tgeChart.update();
        },
        loadChart() {
            document.addEventListener('mousemove', this.onMouseMove, false);
            const ctx = document.getElementById("tgeChart");
            if (this.tgeChart) {
                this.tgeChart.destroy();
            }
            this.tgeChart = new Chart(ctx, this.chartConfig);
        },
        changeSlider() {
            var start = this.zoomRangeValue[0] - 1;
            var end = this.zoomRangeValue[1];

            this.chartData = JSON.parse(JSON.stringify(this.datasets));
            this.chartLabels = JSON.parse(JSON.stringify(this.labels));

            var cat0 = this.chartData[0].data.slice(start, end);
            var cat1 = this.chartData[1].data.slice(start, end);
            var cat2 = this.chartData[2].data.slice(start, end);
            var cat3 = this.chartData[3].data.slice(start, end);
            var cat4 = this.chartData[4].data.slice(start, end);

            this.chartLabels = [];
            this.chartData[0].data = cat0;
            this.chartData[1].data = cat1;
            this.chartData[2].data = cat2;
            this.chartData[3].data = cat3;
            this.chartData[4].data = cat4;

            this.loadChart();
        },
        formatSliderTooltip(val) {
            return this.labels[val-1];
        },

        //chart
        onMouseMove(e) {
            let self = this;
            let mouseX = e.pageX;
            let mouseY = e.pageY;
            if (mouseX < self.xMin || mouseX > self.xMax || mouseY < self.yMin || mouseY > self.yMax) {
                self.tgeChart.clear();
                self.tgeChart.draw();
            }
        },
        changeView(viewName) {
            let self = this;
            if (viewName == "dataset1") {
                self.tgeChart.data = { datasets: datasets1D, labels: labelsDatasets1D };
                currentDataset = datasets1D;
            }
            else {
                self.tgeChart.data = { datasets: datasets2M, labels: labelsDatasets2M };
                currentDataset = datasets2M;
            }
            self.tgeChart.update();
        },
        drawLineWithLabels(e, ctx) {
            let self = this;

            self.tgeChart.clear();
            self.tgeChart.draw();

            const canvasPosition = getRelativePosition(e, self.tgeChart);

            const dataX = self.tgeChart.scales.x.getValueForPixel(canvasPosition.x);
            const dataY = self.tgeChart.scales.y.getValueForPixel(canvasPosition.y);
            const dataY1 = self.tgeChart.scales.y1.getValueForPixel(canvasPosition.y);
            self.xMin = self.tgeChart.chartArea.left;
            self.xMax = self.tgeChart.chartArea.right;
            self.yMin = self.tgeChart.chartArea.top;
            self.yMax = self.tgeChart.chartArea.bottom;
            //console.log(dataX,dataY,dataY1);
            //console.log(canvasPosition.x, canvasPosition.y);
            if (canvasPosition.x > self.xMin && canvasPosition.x < self.xMax && canvasPosition.y > self.yMin && canvasPosition.y < self.yMax) {
                ctx.strokeStyle = "red";
                ctx.beginPath();
                ctx.moveTo(self.xMin, canvasPosition.y);
                ctx.lineTo(self.xMax, canvasPosition.y);
                ctx.stroke();
                ctx.textAlign = "center";
                ctx.textBaseline = 'middle';

                ctx.fillStyle = "red";
                let sideLabelSize = 26;
                ctx.fillRect(self.xMax + 5, canvasPosition.y - (sideLabelSize / 2), sideLabelSize * 1.7, sideLabelSize);
                ctx.fillRect(self.xMin - 5 - sideLabelSize * 2, canvasPosition.y - (sideLabelSize / 2), sideLabelSize * 2, sideLabelSize);

                //dziubki
                //prawy
                ctx.beginPath();
                ctx.moveTo(self.xMax, canvasPosition.y);
                ctx.lineTo(self.xMax + 5, canvasPosition.y - 5);
                ctx.lineTo(self.xMax + 5, canvasPosition.y + 5);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();

                //lewy
                ctx.beginPath();
                ctx.moveTo(self.xMin, canvasPosition.y);
                ctx.lineTo(self.xMin - 5, canvasPosition.y - 5);
                ctx.lineTo(self.xMin - 5, canvasPosition.y + 5);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();

                ctx.fillStyle = "white";
                ctx.fillText(Renderers.numberformat(dataY1, 0, 3, ' ', ','), self.xMax + 14 + (sideLabelSize / 2), canvasPosition.y);
                ctx.fillText(Renderers.amountFormat(Math.round(dataY, 0)), self.xMin - 5 - sideLabelSize, canvasPosition.y);
            }
        },
    },
    watch: {
        
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
