import * as api from '@/api/AccountApi';
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from 'axios';
import CryptoJS from 'crypto-js'
import { Notifications } from '@/scripts/notifications'
import moment from 'moment'

/**
 * User account definition
*/
export const useAccountStore = defineStore('AccountStore', () => {

    // encryption key
    const encryptionKey = computed(() => process.env.VUE_APP_ENCRYPTION_KEY);

    // storage key
    const storageKey = computed(() => process.env.VUE_APP_STORAGE_AUTH_KEY);

    // user account
    const user = computed(() => {
        return getUser();
    });

    // refresh token - stored in memory
    let refreshToken = ref(null);

    // user initials
    const initials = computed(() => {
        return getUser().name
            ?.match(/(^\S\S?|\s\S)?/g)
            ?.map(v => v.trim())
            ?.join("")
            ?.match(/(^\S|\S$)?/g)
            ?.join("")
            ?.toLocaleUpperCase()
    })

    // Check if token is still valid
    const isTokenValid = computed(() => {
        var user = getUser();
        if(!user.isAuthenticated)
        {
            return false;
        }
        
        var now = moment();
        var token = moment(getUser().refreshExpirationDate);
        if(token.diff(now) > 0)
        {
            return true;
        }
            
        return false;
    });

    // get encoded data from storage
    function getFromStorage() {
        return localStorage.getItem(storageKey.value);
    }

    // set encoded data to storage
    function setToStorage(value) {
        localStorage.setItem(storageKey.value, value);
    }

    // delete data from storage
    function deleteFromStorage() {
        localStorage.removeItem(storageKey.value);
    }

    // encrypt json
    function encrypt(value) {
        return CryptoJS.AES.encrypt(value, encryptionKey.value).toString();
    }

    // decrypt to json
    function decrypt(value) {
        if (value == null)
            return null;

        try {
            var result = CryptoJS.AES.decrypt(value, encryptionKey.value).toString(CryptoJS.enc.Utf8);
            return result;
        } catch (exception) { }

        return null;
    }

    /**
     * Get user from storage
     */
    function getUser() {
        var encryptedValue = getFromStorage();
        var value = decrypt(encryptedValue);
        if (value == null)
            return {
                isAuthenticated: false
            };

        return JSON.parse(value);
    }

    /**
     * Save user in storage
     * @param {any} value
     */
    function saveUser(value) {
        var encryptedValue = encrypt(JSON.stringify(value));
        setToStorage(encryptedValue);
    }

    /**
     * Save refresh token from in-memory to storage
     * */
    function beforeUnload() {
        var savedUser = getUser();
        savedUser.refreshToken = refreshToken.value;
        saveUser(savedUser);
    }

    /**
     * Refresh user token using storage temporary token
     * @param {any} success
     * @param {any} fail
     */
    function afterPageLoad() {
        var currentUser = getUser();
        if (currentUser) {
            refreshToken.value = currentUser.refreshToken;
            currentUser.refreshToken = null;
            saveUser(currentUser);
        }
    }

    /**
     * Login user
     * @param {any} username - username
     * @param {any} password - password
     * @param {any} success - success func
     * @param {any} fail - fail func
     */
    function login(username, password, success, fail) {
        api.login(
            username,
            password,
            (response) => {

                var value = {
                    isAuthenticated: true,
                    name: response.data.fullName,
                    login: response.data.userName,
                    token: response.data.access_token,
                    refreshToken: response.data.refresh_token,
                    refreshExpirationDate: moment().add(response.data.refresh_expires_in, 'minutes')
                };
                

                refreshToken.value = response.data.refresh_token;
                saveUser(value);
                success && success(response);
            },
            (response) => {
                fail && fail(response);
            }
        );
    };

    /**
     * Logout user
     */
    function logout() {
        deleteFromStorage();
    }

    /**
     * Login user
     * @param {any} success - success func
     * @param {any} fail - fail func
     */
    function finishSession(success, fail) {
        api.finishSession(
            (response) => {
                success && success(response);
            },
            (response) => {
                fail && fail(response);
            }
        );
    };

    /**
     * Refresh user token using refresh token
     * @param {any} currentRefreshToken
     * @param {any} success
     * @param {any} fail
     */
    function refreshUserToken(currentRefreshToken, success, fail) {
        return api.refreshToken(currentRefreshToken,
            getUser().login,
            (response) => {
                var value = {
                    isAuthenticated: true,
                    name: response.data.fullName,
                    login: response.data.userName,
                    token: response.data.access_token,
                    refreshExpirationDate: moment().add(response.data.refresh_expires_in, 'minutes')
                };

                refreshToken.value = response.data.refresh_token;
                saveUser(value);
                success && success(response);
            },
            (response) => { fail && fail(response); })
    }

    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error)
                prom.reject(error);
            else
                prom.resolve(token);
        });

        failedQueue = [];
    };

    /**
     * Create AXIOS authorization header
     * and refresh token after expired
     * */
    function setAxiosAuthorization() {

        // set authrization header
        axios.interceptors.request.use(
            config => {
                config.headers["Authorization"] = "Bearer " + getUser().token;
                return config;
            });

        // we want to handle error status on interceptor response
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {

            const originalRequest = error.config;

            // if 440 - session expired - timeout 
            if(error.response.status == 440) {
                logout();
                window.location = "/login?url=" + window.location.pathname + window.location.search;
                return Promise.reject(err);
            }

            // Reject promise if usual error
            if (error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject })
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    }).catch(err => {
                        logout();
                        window.location = "/login?url=" + window.location.pathname + window.location.search;
                        return Promise.reject(err);
                    })
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(function (resolve, reject) {
                    refreshUserToken(refreshToken.value, getUser().login,
                        (response) => {
                            var token = getUser().token;
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            processQueue(null, token);
                            resolve(axios(originalRequest));

                        },
                        (err) => {
                            processQueue(err, null);
                            reject(err);
                            logout();
                            window.location = "/login?url=" + window.location.pathname + window.location.search;
                        }).finally(() => { isRefreshing = false; setAxiosAuthorization(); });
                })
            }

 			if (error?.response?.status === 403) {
                Notifications.showForbiddenMessage();
            };

            return Promise.reject(error);
        });
    }

    /**
     * Send ForgotPassword to server 
     * @param {model} model 
     * @param {function} success 
     * @param {function} fail 
     */
    function forgotPassword(model, success, fail) {
        api.forgotPassword(model,
            (response) => { success && success(response); },
            (response) => { fail && fail(response); })
    }

    /**
     * Send ForgotPassword to server 
     * @param {model} model 
     * @param {function} success 
     * @param {function} fail 
     */
    function sendSMSCode(model, success, fail) {
        api.sendSMSCode(model,
            (response) => { success && success(response); },
            (response) => { fail && fail(response); })
    }

    /**
     * Send Reset password to server
     * @param {model} model 
     * @param {function} success 
     * @param {function} fail 
     */
    function resetPassword(model, success, fail) {
        api.resetPassword(model,
            (response) => { success && success(response); },
            (response) => { fail && fail(response); })
    }

    /**
     * Change password on the server
     * @param {model} model 
     * @param {function} success 
     * @param {function} fail 
     */
    function changePassword(model, success, fail) {
        api.changePassword(model,
            (response) => { success && success(response); },
            (response) => { fail && fail(response); })
    }

    /**
     * Get user permissions
     * @param {any} success - success func
     * @param {any} fail - fail func
     */
    function getPermissions(success, fail) {
        api.getPermissions(
            (response) => {
                success && success(response);
            },
            (response) => {
                fail && fail(response);
            }
        );
    };

    return {

        // computed
        user, initials, isTokenValid,

        // methods
        login, logout,
        beforeUnload, afterPageLoad,
        setAxiosAuthorization, getPermissions,

        // api
        forgotPassword, resetPassword, changePassword,
        sendSMSCode, finishSession
    };
});