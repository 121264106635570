import axios from 'axios';

export const getRecords = (apiUrl, params, success, fail) => {
    var url = `${apiUrl}`;
    axios
        .get(url,
            {
                params: params ? params : { searchModel: '[]', pageIndex: 0, pageSize: 0 }
            })
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export const getRecord = (apiUrl, id, success, fail) => {
    var url = `${apiUrl}?id=${id}`;
    axios
        .get(url)
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export const get = (apiUrl, success, fail) => {
    var url = `${apiUrl}`;
    axios
        .get(url)
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export const loadDictionaries = (apiUrl, success, fail) => {
    var url = `${apiUrl}/loaddictionaries`;
    axios
        .get(url)
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export const fetchDictionary = (apiUrl, params, success, fail) => {
    var url = `${apiUrl}`;
    axios
        .get(url,
            {
                params: params ? params : { term: ' ', maxSize: 50 }
            })
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export const add = (apiUrl, model, success, fail) => {
    var url = `${apiUrl}`;
    axios
        .post(url, model)
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export const update = (apiUrl, model, success, fail) => {
    var url = `${apiUrl}`;
    axios
        .post(url, model)
        .then((response) => success(response))
        .catch((response) => fail(response));
};

export const runOperation = (operationUrl, id, params, success, fail) => {
    var url = `${operationUrl}/${id}`;
    axios
        .post(url, params)
        .then((response) => success(response))
        .catch((response) => fail(response));
};
