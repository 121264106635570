import $ from 'jquery'
import * as api from '@/api/BaseApi'
import { Notifications } from '@/scripts/notifications'
import { Logger } from '@/scripts/logger'

export const Dictionaries = {
    data() {
        return {
            apiUrl: '',
            dictModel: [],
        };
    },
    methods: {
        //dictionary loading
        loadDictionaries: function () {
            let self = this;

            api.loadDictionaries(this.apiUrl,
                (response) => {

                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        return;
                    };

                    $.extend(true, self.dictModel, response.data.Model);

                    this.afterLoadDictionaries();
                },
                (error) => {
                    Logger.logException(error);
                }
            );
        },
        afterLoadDictionaries: function () {

        },
        checkAndLoadDictModel: function () {
            if (Object.keys(this.dictModel).length === 0 && this.dictModel.constructor === Object) {
                this.loadDictionaries();
            } else {
                for (var key in this.dictModel) {
                    if (this.dictModel[key] === null) {
                        this.loadDictionaries();
                        break;
                    };
                };
            };
        },

        //remote comboboxes
        fetchDictionary: function (searchTerm, options, apiUrl, forceRefresh, searchModel) {
            let self = this;

            if (!apiUrl) {
                apiUrl = self.apiUrl + '/getcombo';
            };

            var searchParams = {
                term: searchTerm,
                maxSize: 50,
                searchModel: JSON.stringify(searchModel)
            };

            var params = JSON.parse(JSON.stringify(searchParams));

            api.fetchDictionary(apiUrl, params,
                (response) => {
                    self.loading = false;

                    var isCorrectResult = Notifications.showOperationMessage(response.data, true);
                    if (!isCorrectResult) {
                        return [];
                    };

                    self[options] = response.data.Model;

                    return response;
                },
                (error) => {
                    Logger.logException(error);

                    Notifications.showMsg(self.$t('messages.Error'), null, null, true, 'error', error);
                    self.loading = false;
                    return [];
                }
            );
        },
        getDictionaryValues(searchTerm, fieldId, apiUrl, forceRefresh, visible, searchModel) {
            let self = this;

            var options = 'options' + fieldId;

            if (visible) {
                self.fetchDictionary(searchTerm, options, apiUrl, forceRefresh, searchModel);
            };
        },

        //helpersFunction
        isNullOrEmptyCombo: function (rule, value, callback) {
            let self = this;
            if (!self.isNullOrEmpty(value)) {
                callback();
            } else {
                callback(new Error(self.$t('rules.pickValue')));
            }
        },
    }
}