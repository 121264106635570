﻿import axios from 'axios';
import * as base from '@/api/BaseApi';

export const getPage = (params, success, fail) => {
    return base.getRecords("/api/vtransaction/page", params, success, fail);
};

export const get = (id, success, fail) => {
    return base.getRecord("/api/vtransaction/vtransaction", id, success, fail);
};

export const exportList = (model, success, fail) => {
    return base.runOperation("/api/vtransaction/OpVTransactionExportList", 0, model, success, fail);
};
