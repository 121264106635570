<template>
    <h2>
        {{ $t('headers.MyContracts') }}
        <div class="sp-badge sp-badge-no-float" v-loading="parent.contractsCounterLoading"
            element-loading-background="transparent">
            <span v-if="this.parent.contractsTotal > 0">
                {{ this.parent.contractsTotal }}
            </span>
            <span v-else>
                ...
            </span>
        </div>
    </h2>
    <el-carousel :interval="9999999999999" type="card" @change="onContractChange" trigger="click"
        indicator-position="none">
        <el-carousel-item v-for="c in this.contracts" :key="c.Id">
            <el-card class="box-card">
                <template #header>
                    <div class="card-header" style="padding:0px;">
                        <router-link class="sp-list-link" :to="{ name: 'contractform', params: { id: c.Id } }">
                            <el-row :gutter="2">
                                <el-col :lg="12" :md="12" :sm="24">
                                    <h3 style="word-break: break-word;">
                                        <el-tooltip v-if="c.ComputedName?.length > 65" :content="c.ComputedName"
                                            placement="bottom">
                                            {{ Renderers.ellipsis(c.ComputedName, 65) }}
                                        </el-tooltip>
                                        <span v-else>
                                            {{ c.ComputedName }}
                                        </span>
                                    </h3>
                                </el-col>
                                <el-col :lg="12" :md="12" :sm="24">
                                    <h3 style="text-align:right;">{{ c.Symbol }}</h3>
                                </el-col>
                            </el-row>
                        </router-link>
                    </div>
                </template>

                <el-row :gutter="10" class="mar-tb-15">
                    <el-col :lg="16" :md="16" :sm="24">
                        <el-row :gutter="10" class="mar-tb-15">
                            <el-col :lg="12" :md="12" :sm="24">
                                {{ $t('headers.fromDay') }}: {{ Renderers.formatAsDateFromProc(c.FromDate) }}
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="24">
                                {{ $t('headers.toDay') }}: {{ Renderers.formatAsDateFromProc(c.ToDate) }}
                            </el-col>
                        </el-row>
                        <el-row :gutter="10" class="mar-tb-15">
                            <el-col :lg="12" :md="12" :sm="24">
                                {{ $t('headers.medium') }}: {{ Renderers.mediumNames(c.Medium) }}
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="24" v-if="!Helpers.isNullOrEmpty(c.AvgPrice)">
                                {{ $t('headers.mediumPrice') }}: <b>{{ Renderers.amountFormat(c.AvgPrice) }}</b> PLN/MWh
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="24" v-else>
                                {{ $t('headers.mediumPrice') }}: <b>{{ $t('messages.noInfo') }}</b>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :lg="8" :md="8" :sm="24">
                        <div style="padding-left:30px;">
                            <el-progress type="circle" :percentage="c.RealizationPercent ?? 0" width="105"
                                :color="Renderers.percentColor(c.RealizationPercent)" />
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="25" v-if="declarationKinds.length > 0">
                    <el-col :lg="24" :md="24" :sm="24">
                        <router-link class="sp-list-link" style="margin-left:5px;"
                            v-if="(c.Id && c.Medium && c.Medium.includes('EE') && c.CanApplyTranche == true) && declarationKinds.some(x => x.MediumName == 'EE' && x.DeclarationType == 'T')"
                            :to="{ name: 'trancheapplication', params: { contractId: c.Id, medium: 'EE', searchModel: JSON.stringify({ 'MediumType': 'EE' }) } }">
                            <el-button button type="primary" class="sp-button-darkblue">
                                {{ $t('buttons.buyTrancheEnergy') }}
                            </el-button>
                        </router-link>
                        <router-link class="sp-list-link" style="margin-left:5px;"
                            v-if="(c.Id && c.Medium && c.Medium.includes('PR') && c.CanApplyTranche == true) && declarationKinds.some(x => x.MediumName == 'PR' && x.DeclarationType == 'T')"
                            :to="{ name: 'trancheapplication', params: { contractId: c.Id, medium: 'PR', searchModel: JSON.stringify({ 'MediumType': 'PR' }) } }">
                            <el-button button type="primary" class="sp-button-darkblue">
                                {{ $t('buttons.buyTranchePR') }}
                            </el-button>
                        </router-link>
                        <router-link class="sp-list-link" style="margin-left:5px;"
                            v-if="(c.Id && c.Medium && c.Medium.includes('GAS') && c.CanApplyTranche == true) && declarationKinds.some(x => x.MediumName == 'GAS' && x.DeclarationType == 'T')"
                            :to="{ name: 'trancheapplication', params: { contractId: c.Id, medium: 'GAS', searchModel: JSON.stringify({ 'MediumType': 'GAS' }) } }">
                            <el-button button type="primary" class="sp-button-darkblue">
                                {{ $t('buttons.buyTrancheGAS') }}
                            </el-button>
                        </router-link>
                        <router-link class="sp-list-link" style="margin-left:5px;"
                            v-if="(c.Id && c.Medium && c.Medium.includes('EE') && c.CanApplyMandate == true) && declarationKinds.some(x => x.MediumName == 'EE' && x.DeclarationType == 'M')"
                            :to="{ name: 'mandateapplication', params: { contractId: c.Id, medium: 'EE', searchModel: JSON.stringify({ 'MediumType': 'EE' }) } }">
                            <el-button button type="primary" class="sp-button-darkblue">
                                {{ $t('buttons.reportMandateEE') }}
                            </el-button>
                        </router-link>
                        <router-link class="sp-list-link" style="margin-left:5px;"
                            v-if="(c.Id && c.Medium && c.Medium.includes('GAS') && c.CanApplyMandate == true) && declarationKinds.some(x => x.MediumName == 'GAS' && x.DeclarationType == 'M')"
                            :to="{ name: 'mandateapplication', params: { contractId: c.Id, medium: 'GAS', searchModel: JSON.stringify({ 'MediumType': 'GAS' }) } }">
                            <el-button button type="primary" class="sp-button-darkblue">
                                {{ $t('buttons.reportMandateGAS') }}
                            </el-button>
                        </router-link>
                    </el-col>
                </el-row>
            </el-card>
        </el-carousel-item>
    </el-carousel>

</template>

<script>
import $ from 'jquery'
import { Helpers } from '@/scripts/helpers'
import { Renderers } from '@/scripts/renderers'

export default {
    name: 'DashboardContractListCmp',
    props: ['parent', 'contracts', 'total'],
    mixins: [],
    setup() {
        return { Helpers, Renderers };
    },
    data() {
        return {
            declarationKinds: []
        };
    },
    mounted() {

    },
    created() {

    },
    computed: {
    },
    methods: {
        onContractChange: function (newIndex) {
            this.parent.onContractChange(newIndex);
        },
    },
    watch: {

    },
    components: {
    }
}
</script>