<template>
    <div v-if="!indexes || indexes.length == 0">
        {{ $t('messages.NoData') }}
    </div>

    <el-table ref="tradingindexTable" v-bind:data="indexes" border highlight-current-row
        :height="setHeight('tradingindex_table', 270)" @selection-change="handleSelectionChange"
        @current-change="productCurrentChange"
        @expand-change="(row, expanded) => handleExpandChange(row, expanded, 'tradingindexTable')"
        @sort-change="sortChange" v-loading="parent.loadingIndexTable" auto :row-class-name="tableRowClassName"
        @header-changecolumnwidth="changecolumnwidth" @header-dragend="dragend"
        @header-changelayout="headerChangeLayout" id="tradingindex_table" row-key="ProductSymbol"
        :expand-row-keys="expandKeys" style="margin-bottom: 10px;" :current-row-key="indexes[0].ProductSymbol">

        <el-table-column v-if="getColumnVisibility('IndexType')" prop="IndexType" :label="$t('fields.IndexTypeTGE')"
            min-width="180" sortable :indexorder="orderColumns['IndexType']" :width="widthColumns['IndexType']">
            <template #default="props">
                {{ props.row.IndexType }}
            </template>
        </el-table-column>

        <el-table-column v-if="getColumnVisibility('IndexDate')" prop="IndexDate" :label="$t('fields.QuoteDate')"
            min-width="120" :indexorder="orderColumns['IndexDate']" :width="widthColumns['IndexDate']">
            <template #default="props">
                {{ Renderers.formatAsDateFromProc(props.row.IndexDate, "yyyy-MM-DD") }}
            </template>
        </el-table-column>

        <el-table-column v-if="getColumnVisibility('Price')" prop="Price" :label="$t('fields.PriceTGE')" min-width="180"
            sortable :indexorder="orderColumns['Price']" :width="widthColumns['Price']" align="right">
            <template #default="props">
                <span class="text-right">
                    {{ Renderers.amountFormat(props.row.Price) }}
                </span>
            </template>
        </el-table-column>

        <el-table-column v-if="getColumnVisibility('Volume')" prop="Volume" :label="$t('fields.VolumeTGE')"
            min-width="180" sortable :indexorder="orderColumns['Volume']" :width="widthColumns['Volume']" align="right">
            <template #default="props">
                <span class="text-right">
                    {{ Renderers.numberformat(props.row.Volume, 0, 3, ' ', ',') }}
                </span>
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
import $ from 'jquery'
import { Helpers } from '@/scripts/helpers'
import { Renderers } from '@/scripts/renderers'
import { Validators } from '@/scripts/validators'
import { List } from '@/scripts/list'

export default {
    name: "TradingIndexDashboardListCmp",
    mixins: [List],
    props: ['parent', 'listConfiguration', 'indexesList'],
    setup() {
        return { Helpers, Renderers, Validators };
    },
    data() {
        return {
            //from localStorage
            orderColumns: JSON.parse(localStorage.getItem('TradingIndexDashboardListCmp_ColumnOrder')) || ({}),
            widthColumns: JSON.parse(localStorage.getItem('TradingIndexDashboardListCmp_ColumnWidth')) || ({}),
            columnListVisibility: JSON.parse(localStorage.getItem('TradingIndexDashboardListCmp_ColumnVisibility')) || [
                { column: 'ChangeDate', value: false },
                { column: 'CreateDate', value: false },
                
                { column: 'Id', value: false },
                { column: 'IndexType', value: true },
                { column: 'IndexDate', value: true },
                { column: 'Price', value: true },
                
                { column: 'TradingSessionId', value: true },
               
                { column: 'Volume', value: true },
                { column: 'expand', value: false },
                { column: 'selection', value: false }
            ],

            //page configurations
            pageName: 'TradingIndexDashboardListCmp',
            pageObjectName: 'TradingIndex',
            apiUrl: "/api/tradingindex/",
            pageSize: 20,
            pageSizes: [10, 20, 50, 100, 300],

            //data
            indexes: [],
        };
    },
    created() {
        this.setFromProps();
    },
    mounted() {
        this.$refs.tradingindexTable?.doLayout();
        this.setHeight('tradingindex_table', 270);
    },
    methods: {
        //initializing
        setFromProps: function () {
            this.configuration = $.extend(true, this.configuration, this.listConfiguration);
            this.columnListVisibility = this.listConfiguration.columnListVisibility;
            this.indexes = this.indexesList;
        },

        productCurrentChange: function (currentRow, oldCurrentRow) {
            this.parent.selectedProduct = currentRow;
            if (oldCurrentRow != null) //żeby nie ładować na starcie strony trzykrotnie listy
                this.parent.fetchChartTGETradingIndex(currentRow.ProductSymbol);
        },
    },
}
</script>
