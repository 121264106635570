<template>
    <el-table ref="quotationTable" v-bind:data="quotations" border highlight-current-row
        :height="setHeight('quotation_table', 270)" @selection-change="handleSelectionChange"
        @current-change="productCurrentChange"
        @expand-change="(row, expanded) => handleExpandChange(row, expanded, 'quotationTable')"
        @sort-change="sortChange" v-loading="parent.loadingQuotationTable" auto :row-class-name="tableRowClassName"
        @header-changecolumnwidth="changecolumnwidth" @header-dragend="dragend"
        @header-changelayout="headerChangeLayout" id="quotation_table" row-key="ProductSymbol"
        :expand-row-keys="expandKeys" style="margin-bottom: 10px;" :current-row-key="quotations[0].ProductSymbol">

        <el-table-column v-if="getColumnVisibility('ProductSymbol')" prop="ProductSymbol" :label="$t('fields.Product')"
            min-width="180" :indexorder="orderColumns['ProductSymbol']" :width="widthColumns['ProductSymbol']">
            <template #default="props">
                {{ props.row.ProductSymbol }}
            </template>
        </el-table-column>

        <el-table-column v-if="getColumnVisibility('QuoteDate')" prop="QuoteDate" :label="$t('fields.QuoteDate')"
            min-width="120" :indexorder="orderColumns['QuoteDate']" :width="widthColumns['QuoteDate']">
            <template #default="props">
                {{ Renderers.formatAsDateFromProc(props.row.QuoteDate, "yyyy-MM-DD") }}
            </template>
        </el-table-column>

        <el-table-column v-if="getColumnVisibility('DailySettlementPrice')" prop="DailySettlementPrice"
            :label="$t('fields.DailySettlementPriceTGE')" min-width="135" align="right" :indexorder="orderColumns['DailySettlementPrice']"
            :width="widthColumns['DailySettlementPrice']">
            <template #default="props">
                <span :class="['text-right ' + props.row.PriceColor]">
                    {{ Renderers.amountFormat(props.row.DailySettlementPrice) }}
                </span>
                <el-popover v-if="props.row.PriceColor == 'text-red'" placement="bottom" :width="350" trigger="click">
                    <template #reference>
                        <a href="#">
                            <span class="mdi mdi-information-outline mdi-18px text-red text-right"
                                style="position: absolute; margin-left: 55px"></span>
                        </a>
                    </template>
                    <h3>{{ $t('headers.information') }}</h3>
                    <el-divider></el-divider>
                    {{ $t('temp.RateInfo') }}
                </el-popover>
            </template>
        </el-table-column>

        <el-table-column v-if="getColumnVisibility('Lots')" prop="Lots" :label="$t('fields.Lots')" min-width="180"
            :indexorder="orderColumns['Lots']" :width="widthColumns['Lots']" align="center">
            <template #default="props">
                {{ props.row.Lots }}
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
import $ from 'jquery'
import { Helpers } from '@/scripts/helpers'
import { Renderers } from '@/scripts/renderers'
import { Validators } from '@/scripts/validators'
import { List } from '@/scripts/list'

export default {
    name: "QuotationDashboardListCmp",
    mixins: [List],
    props: ['parent', 'listConfiguration', 'quotationsList'],
    setup() {
        return { Helpers, Renderers, Validators };
    },
    data() {
        return {
            //from localStorage
            orderColumns: JSON.parse(localStorage.getItem('QuotationDashboardListCmp_ColumnOrder')) || ({}),
            widthColumns: JSON.parse(localStorage.getItem('QuotationDashboardListCmp_ColumnWidth')) || ({}),
            columnListVisibility: JSON.parse(localStorage.getItem('QuotationDashboardListCmp_ColumnVisibility')) || [
                { column: 'ChangeDate', value: true },

                { column: 'CreateDate', value: true },

                { column: 'DailySettlementPrice', value: true },
                { column: 'FirstTradePrice', value: true },
                { column: 'Id', value: false },
                { column: 'Lots', value: true },
                { column: 'MaximumPrice', value: true },
                { column: 'MinimumPrice', value: true },
                { column: 'NumberTransactions', value: true },
                { column: 'ProductSymbol', value: true },

                { column: 'TotalValue', value: true },
                { column: 'TotalVolume', value: true },
                { column: 'TradingSessionId', value: true },
                { column: 'QuoteDate', value: true },
                { column: 'MediumType', value: true },

                { column: 'expand', value: true },
                { column: 'selection', value: true },
            ],

            //page configurations
            pageName: 'QuotationDashboardListCmp',
            pageObjectName: 'Quotation',
            apiUrl: "/api/quotation/",
            pageSize: 20,
            pageSizes: [10, 20, 50, 100, 300],

            //data
            quotations: [],
        };
    },
    created() {
        this.setFromProps();
    },
    mounted() {
        this.$refs.quotationTable?.doLayout();
        this.setHeight('quotation_table', 270);
    },
    methods: {
        //initializing
        setFromProps: function () {
            this.configuration = $.extend(true, this.configuration, this.listConfiguration);
            this.columnListVisibility = this.listConfiguration.columnListVisibility;
            this.quotations = this.quotationsList;
        },

        productCurrentChange: function (currentRow, oldCurrentRow) {
            this.parent.selectedProduct = currentRow;
            if (oldCurrentRow != null) { //żeby nie ładować na starcie strony trzykrotnie listy
                if (currentRow.IsIndex == true) {
                    //wykres dla indeksów
                    this.parent.fetchChartTGETradingIndex(currentRow.ProductSymbol);
                    this.parent.showIndexChart = true;
                }
                else {
                    this.parent.fetchChartTGEQuotationsForProduct(currentRow.ProductSymbol);
                    this.parent.showIndexChart = false;
                };
            }
        },
    },
}
</script>
