import $ from 'jquery'
import { markRaw } from 'vue'
import { QuestionFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import { operationResult } from './const'
import _ from 'lodash'
import { Notifications } from './notifications'
import { operationExecutionType } from './const'
import * as api from '@/api/BaseApi';

export const Operations = {
    mixins: [],
    data() {
        return {
            apiUrl: '',
            currentOperationSymbol: '',
            operations: {},
            operation: {},
            operationModel: [],
            startedOperations: [],
            opPercentage: 0,
            opCounter: 0,
            opExecution: null,
            showDlgOperation: false,
            showProgress: false,
            selectedRows: [],
            showOnlyNotifyResult: false, //zamiast okna z progressami pokazuje notyfikację dla każdej operacji
            enableExtendOperationWindow: false, //właściwość na konkretnej stronie dla uniknięcia #750
            showExtendOperationWindow: false,
            allOperationFinished: false,
        };
    },
    computed: {
        isOperationRun: function () {
            return this.operation != null && this.operation != '' && (this.showDlgOperation || this.startedOperations.length > 0);
        },
        selectedRowsCounter: function () {
            if (this.selectedRows && this.selectedRows.length > 1)
                return ` (${this.selectedRows.length}) `;
            else
                return '';
        },
        orderedOperations: function () {
            return _.orderBy(this.operations, "SortOrder");
        },
        operationsForList: function () {
            const filtered = Object.entries(this.operations)?.filter(([key, value]) => value.ForList == true);
            const operationList = Object.fromEntries(filtered);

            return operationList;
        },
    },
    methods: {
        closeExecution: function () {
            // if (this.opPercentage != 100) {
            //     ElMessageBox.confirm(
            //         this.$t('headers.confirm'),
            //         this.$t('headers.confirm'))
            //         .then(() => {
            //             //go
            //         })
            //         .catch(() => {
            //             return;
            //         })
            // };

            let opSymbol = this.currentOperationSymbol;

            this.showProgress = false;
            this.loading = false;
            this.operation = null;
            this.startedOperations = [];
            this.opPercentage = 0;
            this.opCounter = 0;
            this.currentOperationSymbol = "";
            //this.selectedRows = [];

            this.doAfterOperationExecution(opSymbol);
        },
        doAfterOperationExecution(opSymbol) {
            if (typeof this.afterOperationExecuted === "function") {
                this.afterOperationExecuted(opSymbol);
            }
            else if (typeof this.fetchAfterSave === "function") {
                this.fetchAfterSave();
            }
            else {
                this.fetchList();
            };
        },
        operationDone: function () {

        },
        allOperationsDone: function () {
            this.allOperationFinished = true;

            return this.allOperationFinished;
        },
        handleProgressOpenedSync: function (processData, done) {
            let self = this;
            var data = this.startedOperations;

            if (data.length > 0) {
                var loop = function (data, i, processData, done) {
                    processData(data[i], data[i].Operation.Model.Id, function () {
                        if (++i < data.length) {
                            loop(data, i, processData, done);
                        } else {
                            //self.allOperationsDone(); //wykonuje się z watch this.startedOperations
                        };

                    });
                };

                loop(data, 0, processData, done);
            }
            else {
                //self.allOperationsDone(); //wykonuje się z watch this.startedOperations
            }
        },
        handleProgressOpenedAsync: function () {
            this.startedOperations.forEach(op => {
                this.runOperation(op, op.Operation.Model.Id);
            }
            );
        },
        isStartedSyncOperation: function () {
            //jeśli przynajmniej jedna ze zleconych operacji ma atrybut SYNC to wykonujemy operacje synchronicznie
            var isAnySYNC = this.startedOperations.filter(obj => {
                return obj.Operation.Operacja.ExecutionType === operationExecutionType.SYNC;
            });

            if (isAnySYNC && isAnySYNC.length > 0) {
                return true;
            };

            return false;
        },
        handleProgressOpened: function () {
            this.allOperationFinished = false;

            if (this.isStartedSyncOperation()) {
                //sync
                this.handleProgressOpenedSync(this.runOperation, this.operationDone);
            }
            else {
                //async
                this.handleProgressOpenedAsync();
            };
        },
        run: function () {
            let formName = 'form' + this.operation.Symbol;

            if (this.$refs[formName] && this.$refs[formName].$refs && this.$refs[formName].$refs.formOperation) {
                this.$refs[formName].$refs.formOperation.validate((valid, fields) => {
                    if (valid) {
                        this.operation.Model = this.operationModel;
                        this.runIfValid();
                    }
                    else {
                        return false;
                    }
                });
            }
            else {
                this.runIfValid();
            };
        },
        runIfValid: function () {
            if (this.operation) {
                this.showDlgOperation = false;
                this.showProgress = true;
                let i = 0;
                if (this.selectedRows) {
                    this.selectedRows.forEach(item => {
                        this.runForItem(item, this.operation, i);
                    });
                };
            }
            this.showDlgOperation = false;
        },
        runForItem(item, operation, i) {
            //this.operation.Model = {
            //    Id: item.Id
            //};
            if (this.operation.Model === null) {
                this.operation.Model = {};
            };

            // JS@2020-10-13 Jeśli przekazano Id encji w modelu operacji to wykonaj dla tego Id, w przeciwnym wypadku wykonaj w kontekście głownego obiektu
            this.operation.Model.Id = operation.Entity_id ? operation.Entity_id : item.Id;

            // Pg, trzeba przekazać wartość, bo inaczej idzie referencja
            // i przy wielu rekordach wykonuje operation wykonuje się i tak na ostatnim
            var operationCopy = $.extend(true, {}, this.operation); //Object.assign({}, this.operation); //ep:uwaga: object.assign robi poważne kuku

            var started = {
                Operation: operationCopy,
                Id: item.Id,
                Title: item['DisplayName'] ?? item['Name'] ?? item['Title'] ?? (item['Id'] != '0' ? 'Id #' + item['Id'] : ''),
                OperationName: operationCopy.Nazwa,
                Percentage: 0,
                Status: "",
                Message: "",
                Row: i++
            };
            this.startedOperations.push(started);
        },
        runOperation(operation, id, callback) {
            const self = this;
            this.loading = true;

            operation.Percentage = 25;

            //obsługa dat
            for (var prop in operation.Operation.Model) {
                var d = (operation.Operation.Model)[prop];
                var isDate = d && Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d);
                if (isDate) {
                    (operation.Operation.Model)[prop] = formatAsDate(d);
                }
            };

            let params = JSON.parse(JSON.stringify(operation.Operation.Model));
            // JS@2020-10-13 Jeśli przekazano apiUrl w modelu operacji to wykonaj dla tego apiUrl, w przeciwnym wypadku wykonaj dla apiUrl głownego obiektu
            let httpApiUrl = operation.Operation.ApiUrl ? operation.Operation.ApiUrl : this.apiUrl;

            this.currentOperationSymbol = operation.Operation.Symbol;

            api.runOperation(httpApiUrl + operation.Operation.Symbol, id, params,
                (response) => {
                    if (response.data.Status != operationResult.OK) {
                        var errorMsg = Notifications.prepareErrorMessage(response.data);

                        operation.Status = "exception";
                        operation.Message = errorMsg.Short;
                        operation.ExtendedMessage = errorMsg.CompleteHtml;

                        operation.Percentage = 100;
                        ++this.opCounter;
                        this.opPercentage = (this.opCounter) / (this.startedOperations.length) * 100;

                        if (this.showOnlyNotifyResult) {
                            ElMessage({
                                type: 'error',
                                message: self.$t('messages.RunOperationError', { operation: operation.OperationName }) + '<br/><br/>' + operation.ExtendedMessage,
                                duration: 15000,
                                showClose: true,
                                dangerouslyUseHTMLString: true
                            });
                        };
                    }
                    else {
                        //todo:przeładowanie
                        operation.Status = "success";
                        operation.Percentage = 100;
                        operation.Message = self.$t('messages.OperationSuccess');
                        operation.ExtendedMessage = response.data.Komunikat != null ? response.data.Komunikat : '';

                        //todo! typ operacji download
                        if (operation !== null && operation.Operation !== null && operation.Operation.Symbol === "OpDocumentGetReport" && response.headers.location !== null) {
                            //var win = window.open(response.headers.location, '_blank');
                            //win.focus();
                            operation.Message = self.$t('messages.OperationSuccess');
                            operation.LinkUrl = response.headers.location;
                            operation.LinkName = self.$t('consts.fileLink');
                        };

                        if (this.showOnlyNotifyResult) {
                            ElMessage({
                                type: 'success',
                                message: self.$t('messages.RunOperationSuccess', { operation: operation.OperationName }),
                                duration: 5000,
                                showClose: true,
                                dangerouslyUseHTMLString: true
                            });
                            this.closeExecution();
                        };
                    }

                    ++this.opCounter;
                    this.opPercentage = (this.opCounter) / (this.startedOperations.length) * 100;

                    if (callback)
                        callback();
                },
                (error) => {
                    var errorMessage = self.$t('messages.Error');

                    if (error?.response?.status === 403) {
                        errorMessage = self.$t('error.ForbiddenException');
                    };

                    operation.Status = "exception";
                    operation.Message = errorMessage;
                    operation.ExtendedMessage = Notifications.getErrorInfo(error);

                    operation.Percentage = 100;
                    ++this.opCounter;
                    this.opPercentage = (this.opCounter) / (this.startedOperations.length) * 100;

                    if (this.showOnlyNotifyResult) {
                        ElMessage({
                            type: 'error',
                            message: self.$t('messages.RunOperationError', { operation: operation.OperationName }) + '<br/><br/>' + operation.ExtendedMessage,
                            duration: 15000,
                            showClose: true
                        });
                    };

                    if (callback)
                        callback();
                }
            );
        },
        prepareOperations(model) {
            this.enableExtendOperationWindow = true;
            var self = this;
            self.operations = {};

            if (!model.Operacje) {
                return;
            };

            model.Operacje.forEach(op => {
                if (!self.operations[op.Symbol]) {
                    self.operations[op.Symbol] = op;
                }
                else {
                    Object.keys(op).forEach(key => {
                        if (!self.operations[op.Symbol][key]) {
                            self.operations[op.Symbol][key] = op[key];
                        }
                    });
                };

                self.operations[op.Symbol].Kolor = op.Kolor || op.ButtonCls;
                self.operations[op.Symbol].Ikona = op.Icon || '';
                self.operations[op.Symbol].ForList = op.ForList;
                if (op.TypOperacji) self.operations[op.Symbol].IsConfirmed = op.TypOperacji === 2;
                if (op.TypOperacji) self.operations[op.Symbol].IsExtended = op.TypOperacji === 3;

            });

            Object.keys(self.operations).forEach(key => {
                if (this[key]) {
                    self.operations[key].Run = this[key];
                }
                else
                    self.operations[key].Run = this.run;
                let formName = 'form' + key;
            });
        },
        prepareCurrentOperation(command) {
            this.enableExtendOperationWindow = true;
            this.operation = this.operations[command] || this.operations.find(obj => {
                return obj.Symbol === command;
            });

            this.operationModel = this.operation ? this.operation.Model : null;
            if (this.operation) {
                if (this.operation.IsConfirmed || this.operation.IsExtended) {
                    this.showDlgOperation = true;
                }
                else {
                    this.operation.Run();
                }
            }
            else {
                try {
                    command();
                }
                catch (ex) {
                    console.error(ex); //todo
                }
            }
        },
        handleMenuCommand(command) {
            this.enableExtendOperationWindow = true;
            this.prepareCurrentOperation(command);
        },
        setOperationResultsDialogModel() {
            this.$root.operationResultsDialogModel = this.startedOperations;
            this.$root.operationResultsDialogModel.closeExecution = this.closeExecution;
        }
    },
    watch: {
        showDlgOperation(value) {
            this.$root.operationsController = this;
            this.$root.operationConfirmDialogVisible = value && !this.operation.IsExtended; //dla extended pokazujemy dialog z parametrami na zagadnieniu
            this.$root.operationConfirmDialogExtendedVisible = value && this.operation.IsExtended && this.enableExtendOperationWindow;
            this.showExtendOperationWindow = value && this.operation.IsExtended && this.enableExtendOperationWindow;
            this.$root.operationConfirmDialogModel = $.extend(true, {}, this.operation);
            this.$root.operationConfirmDialogModel.selectedRowsCounter = this.selectedRowsCounter;
            this.$root.operationConfirmDialogModel.caller = this;
        },
        showProgress(value) {
            if (value) {
                if (!this.showOnlyNotifyResult) {
                    this.$root.operationResultsDialogVisible = value;
                };

                this.setOperationResultsDialogModel();

                this.handleProgressOpened();
            }
        },
        startedOperations: {
            handler: function (val, oldVal) {
                var isAnyInProgress = this.startedOperations.filter(x => x.Percentage != 100).length;
                if (isAnyInProgress == 0 && !this.allOperationFinished) {
                    this.allOperationsDone();
                };

                this.setOperationResultsDialogModel();
            },
            deep: true
        },
        allOperationFinished(value) {
            this.$root.operationResultsDialogModel.allOperationFinished = value;
        },
    }
}